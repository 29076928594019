import React, { useCallback } from "react";
import { Confirmar } from "../../../components";
import { useDispatch } from "react-redux";
import { useToggle } from "react-use";
import { trainningCloseModal, trainningDelete } from "./treinamento-actions";
import { useTranslation } from "react-i18next";

function TreinamentoExcluir({ id }) {
  const { t } = useTranslation();
  const [wait, setWait] = useToggle();
  const dispatch = useDispatch();
  const onConfirm = useCallback(() => {
    dispatch(trainningDelete({ id_treinamento: id }, setWait));
  }, [id, setWait, dispatch]);

  const onCancel = useCallback(() => {
    dispatch(trainningCloseModal());
  }, [dispatch]);
  return (
    <Confirmar
      titulo={t("treinamento.titlePageDeleteTrainning")}
      subtitulo={t("treinamento.subtitlePageDeleteTrainning")}
      aguardar={wait}
      fnCancelar={onCancel}
      fnConfirmar={onConfirm}
    />
  );
}

export default TreinamentoExcluir;
