import { toast } from "react-toastify";
import fetchRedux from "../../../api/fetch_redux";
import { ToastErro } from "../../../utils/utils";

export const PRIZE_DRAW_INIT = "PRIZE_DRAW_INIT";
export const PRIZE_DRAW_DATES = "PRIZE_DRAW_DATES";
export const PRIZE_DRAW_SET_MODAL = "PRIZE_DRAW_SET_MODAL";
export const PRIZE_DRAW_CLOSE_MODAL = "PRIZE_DRAW_CLOSE_MODAL";
export const PRIZE_DRAW_ADD = "PRIZE_DRAW_ADD";
export const PRIZE_DRAW_UPD = "PRIZE_DRAW_UPD";
export const PRIZE_DRAW_DEL = "PRIZE_DRAW_DEL";
export const PRIZE_DRAW_ESCALONATE_CALENDAR = "PRIZE_DRAW_ESCALONATE_CALENDAR";
export const PRIZE_DRAW_ESCALONATE_AUDITOR_INIT =
  "PRIZE_DRAW_ESCALONATE_AUDITOR_INIT";
export const PRIZE_DRAW_ESCALONATE_AUDITOR_PUT =
  "PRIZE_DRAW_ESCALONATE_AUDITOR_PUT";

export const PRIZE_DRAW_ESCALONATE_AUDITOR_DEL =
  "PRIZE_DRAW_ESCALONATE_AUDITOR_DEL";

const ROUTES = [
  "/escalonada_api",
  "/escalonada_calendario_api",
  "/escalonada_auditores_api",
];

export const prizeDrawInit = (setWait) => (dispatch) => {
  fetchRedux(
    `${ROUTES[0]}?dados=true`,
    "GET",
    null,
    (response) => {
      dispatch({
        type: PRIZE_DRAW_INIT,
        data: response,
      });
    },
    () => {},
    (err) => ToastErro(err)
  );

  dispatch(prizeDrawEscalonateAuditorInit());
};

export const prizeDrawDates = (setWait, dateFrom, dateTo) => (dispatch) => {
  fetchRedux(
    `${ROUTES[0]}?de=${dateFrom}&ate=${dateTo}`,
    "GET",
    null,
    (response) => {
      dispatch({
        type: PRIZE_DRAW_DATES,
        data: response,
      });
    },
    () => {
      setWait();
    },
    (err) => ToastErro(err)
  );
};

export const prizeDrawSetModal = (data) => ({
  type: PRIZE_DRAW_SET_MODAL,
  data,
});

export const prizeDrawCloseModal = () => ({
  type: PRIZE_DRAW_CLOSE_MODAL,
});

export const prizeDrawAddUpd = (data, setWait) => (dispatch) => {
  fetchRedux(
    `${ROUTES[0]}`,
    data?.id ? "PUT" : "POST",
    data,
    (response) => {
      toast.success(response.sucesso);
      //
      dispatch({
        type: data?.id ? PRIZE_DRAW_UPD : PRIZE_DRAW_ADD,
        data: response.data,
      });
    },
    () => {
      setWait();
    },
    (err) => ToastErro(err)
  );
};

export const prizeDrawDel = (data, setWait) => (dispatch) => {
  fetchRedux(
    ROUTES[0],
    "DELETE",
    data,
    (response) => {
      toast.success(response.sucesso);
      //
      dispatch({
        type: PRIZE_DRAW_DEL,
        data: data.id,
      });
    },
    () => {
      setWait();
    },
    (err) => ToastErro(err)
  );
};

export const prizeDrawEscalonateCalendar = (data, setWait) => (dispatch) => {
  fetchRedux(
    `${ROUTES[1]}`,
    "GET",
    new URLSearchParams(data),
    (response) => {
      //
      dispatch({
        type: PRIZE_DRAW_ESCALONATE_CALENDAR,
        data: response.itens,
      });
    },
    () => {
      setWait();
    },
    (err) => ToastErro(err)
  );
};

export const prizeDrawEscalonateAuditorInit = () => (dispatch) => {
  fetchRedux(
    ROUTES[2],
    "GET",
    null,
    (response) => {
      dispatch({
        type: PRIZE_DRAW_ESCALONATE_AUDITOR_INIT,
        data: response,
      });
    },
    () => {},
    (err) => ToastErro(err)
  );
};

export const prizeDrawEscalonateAuditorPut = (data, setWait) => (dispatch) => {
  fetchRedux(
    ROUTES[2],
    "PUT",
    data,
    (response) => {
      toast.success(response.sucesso);
      //
      dispatch({
        type: PRIZE_DRAW_ESCALONATE_AUDITOR_PUT,
        data: response.data,
      });
    },
    () => {
      setWait();
    },
    (err) => ToastErro(err)
  );
};

export const prizeDrawEscalonateAuditorDel = (data, setWait) => (dispatch) => {
  fetchRedux(
    ROUTES[2],
    "DELETE",
    data,
    (response) => {
      toast.success(response.sucesso);
      //
      dispatch({
        type: PRIZE_DRAW_ESCALONATE_AUDITOR_DEL,
        data: data,
      });
    },
    () => {
      setWait();
    },
    (err) => ToastErro(err)
  );
};
