import { Stack } from "@mui/material";
import React, { useCallback } from "react";
import * as yup from "yup";
import { VALIDADOR_TIPO, obterValidador } from "../../../utils/validadores";
import { EntradaForm, H6 } from "../../../components";
import { useToggle } from "react-use";
import { useDispatch, useSelector } from "react-redux";
import { selectColaboradoresFormatados } from "./helpdesk-seletores";
import { helpdeskUpdInvolved } from "./helpdesk-actions";
import { useTranslation } from "react-i18next";

const FIELD_FORM = {
  involved: "copia_chamado",
};

function HelpdeskAlterarEnvolvidos({ idTicket, envolvidos }) {
  const { t } = useTranslation();
  const [wait, setWait] = useToggle();
  const dispatch = useDispatch();
  const listOfColabs = useSelector(selectColaboradoresFormatados);
  console.log(listOfColabs);

  const schema = [
    {
      type: "select",
      name: FIELD_FORM.involved,
      label: t("helpdesk.labelInvolved"),
      itens: listOfColabs,
      isMulti: true,
      autoFormat: true,
      defaultValue: envolvidos?.map((ele) => [
        `${ele.matricula}_${ele.planta}`,
        ele.nome,
      ]),
      placeholder: t("helpdesk.labelInvolved"),
    },
  ];

  const schemaMessageError = {
    [FIELD_FORM.involved]: t("helpdesk.errorInvolved"),
  };

  const schemaValidator = yup.object().shape({
    [FIELD_FORM.involved]: obterValidador(VALIDADOR_TIPO.selectMultiplo),
  });
  //
  const onSubmit = useCallback(
    (val) => {
      const obj = {
        id_ticket: idTicket,
        [FIELD_FORM.involved]: val[FIELD_FORM.involved].map((ele) => ele.value),
      };

      dispatch(helpdeskUpdInvolved(obj, setWait));
    },
    [setWait, dispatch, idTicket]
  );
  //
  return (
    <Stack>
      <H6>{t("helpdesk.titleAlterEnvolved")}</H6>
      <EntradaForm
        schema={schema}
        schemaMessageError={schemaMessageError}
        schemaValidator={schemaValidator}
        wait={wait}
        onSubmit={onSubmit}
      />
    </Stack>
  );
}

export default HelpdeskAlterarEnvolvidos;
