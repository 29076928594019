import React, { useCallback } from "react";
import { useDispatch } from "react-redux";
import { useToggle } from "react-use";
import {
  prizeDrawEscalonateAuditorDel,
  prizeDrawSetModal,
} from "../escalonada-actions";
import { Container } from "@mui/material";
import { Confirmar } from "../../../../components";

const STR = {
  titleAuditDel: "Remover auditor",
  subtitleAuditDel: "Deseja realmente remover o auditor?",
};

function EscalonadaAuditorDel({ idAudit, mat, plant }) {
  const dispatch = useDispatch();
  const [wait, setWait] = useToggle();
  //
  const onCancel = useCallback(() => {
    dispatch(prizeDrawSetModal(null));
  }, [dispatch]);
  //
  const onConfirm = useCallback(() => {
    dispatch(
      prizeDrawEscalonateAuditorDel(
        { id_auditoria: idAudit, matricula: mat, planta: plant },
        setWait
      )
    );
  }, [dispatch, idAudit, mat, plant, setWait]);
  //
  return (
    <Container>
      <Confirmar
        titulo={STR.titleAuditDel}
        subtitulo={STR.subtitleAuditDel}
        fnConfirmar={onConfirm}
        fnCancelar={onCancel}
        aguardar={wait}
      />
    </Container>
  );
}

export default EscalonadaAuditorDel;
