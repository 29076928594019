import React, { useCallback } from "react";
import { useDispatch } from "react-redux";
import { prizeDrawCloseModal, prizeDrawDel } from "./escalonada-actions";
import { useToggle } from "react-use";
import { Confirmar } from "../../../components";

const STR = {
  titleDel: "Excluir a escalonada selecionada ?",
  subtitleDel:
    "Esta ação não poderá ser desfeita, você realmente deseja excluir a escalonada selecionada ?",
};

function EscalonadaDel({ id }) {
  const [wait, setWait] = useToggle();

  const dispatch = useDispatch();
  //
  const onConfirm = useCallback(() => {
    console.log(id);
    dispatch(prizeDrawDel({ id }, setWait));
  }, [dispatch, id, setWait]);
  //
  const onCancel = useCallback(() => {
    dispatch(prizeDrawCloseModal());
  }, [dispatch]);
  //
  return (
    <Confirmar
      aguardar={wait}
      fnCancelar={onCancel}
      fnConfirmar={onConfirm}
      titulo={STR.titleDel}
      subtitulo={STR.subtitleDel}
    />
  );
}

export default EscalonadaDel;
