import { createSelector } from "reselect";

export const selectPrizeDraw = (state) => state?.prizeDraw;
export const selectPrizeDrawItems = (state) => state?.prizeDraw?.itens;
export const selectPrizeDrawModal = (state) => state?.prizeDraw?.modal;
export const selectPrizeDrawArea = (state) => state?.prizeDraw?.area;
export const selectPrizeDrawAreaXModel = (state) =>
  state?.prizeDraw?.area_x_modelo;
export const selectPrizeDrawClassification = (state) =>
  state?.prizeDraw?.classificacao;

export const selectPrizeDrawEscalonateCalendar = (state) =>
  state?.prizeDraw?.escalonateCalendar;

export const selectAudit = (state) => state?.prizeDraw?.auditorias;
export const selectAuditors = (state) => state?.prizeDraw?.auditores;
export const selectColab = (state) => state?.prizeDraw?.colaboradores;
export const isAdminEscalonada = (state) =>
  state?.usuario?.variaveis?.includes("ESCALONADA_ADMIN");

export const selectorColabFormat = createSelector(selectColab, (colabs) => {
  return colabs?.map((colab) => [
    `${colab.matricula}_${colab.planta}`,
    `${colab.matricula} - ${colab.nome}`,
  ]);
});

export const selectorAuditFormat = createSelector(selectAudit, (audits) => {
  return audits?.map((audit) => [
    `${audit.id}`,
    `Nível ${audit.id} - ${audit.nome}`,
  ]);
});
