import { useCallback } from "react";
import { useDispatch } from "react-redux";
import { prizeDrawSetModal } from "../escalonada-actions";
import EscalonadaModal from "../escalonada-modal";
import OptionsMenu from "../../../../components/options-menu";

const STR = {
  titleViewPrizeDraw: "VISUALIZAR ESCALONADA",
  labelViewPrizeDraw: "VISUALIZAR ESCALONADA",
  titleQuestionPrizeDraw: "RESPONDER QUESTIONÁRIO",
  labelQuestionPrizeDraw: "RESPONDER QUESTIONÁRIO",
  titleQuestionViewPrizeDraw: "VISUALIZAR RESPOSTAS",
  labelQuestionViewPrizeDraw: "VISUALIZAR RESPOSTAS",
};

const PrizeDrawOptionsAuditor = ({ id, item }) => {
  const dispatch = useDispatch();
  //
  const onView = useCallback(() => {
    dispatch(
      prizeDrawSetModal({
        type: EscalonadaModal.modal.VIEW,
        data: item,
      })
    );
  }, [dispatch, item]);
  //
  const onResponseQuestion = useCallback(() => {
    dispatch(
      prizeDrawSetModal({
        type: EscalonadaModal.modal.RESPONSE_QUESTION,
        data: item,
      })
    );
  }, [dispatch, item]);
  //
  const onViewAnswer = useCallback(() => {
    dispatch(
      prizeDrawSetModal({
        type: EscalonadaModal.modal.VIEW_ANSWER,
        data: item,
      })
    );
  }, [dispatch, item]);

  const options = [
    {
      icon: "Visibility",
      title: STR.titleViewPrizeDraw,
      label: STR.labelViewPrizeDraw,
      onClick: onView,
    },
    {
      icon: "QuestionAnswer",
      title: STR.titleQuestionViewPrizeDraw,
      label: STR.labelQuestionViewPrizeDraw,
      onClick: onViewAnswer,
    },
    {
      icon: "Rule",
      title: STR.titleQuestionPrizeDraw,
      label: STR.labelQuestionPrizeDraw,
      onClick: onResponseQuestion,
    },
  ];

  return <OptionsMenu options={options} />;
};

export default PrizeDrawOptionsAuditor;
