/**
 * Gera a quantidade de treinamentos realizados por mês
 *
 */

import { createSelector } from "reselect";
import { selectIsMat } from "../../../utils/global-selectors";

export const selectTrainningData = (state) => state.treinamento;
export const selectTrainningItems = (state) => state?.treinamento?.registers;
export const selectModal = (state) => state?.treinamento?.modal;
export const selectTrainningApply = (state) => state?.treinamento?.aplicaveis;
export const selectTrainningDevelop = (state) =>
  state?.treinamento?.desenvolvimento;
export const selectTrainningEfficiency = (state) =>
  state?.treinamento?.eficacia;
export const selectTrainningTypeOfCourse = (state) =>
  state?.treinamento?.tipo_curso;
export const selectTrainningInstructor = (state) =>
  state?.treinamento?.instrutores;
export const selectTrainningCouse = (state) => state?.treinamento?.cursos;
export const selectStudents = (state) => state?.treinamento?.alunos;
export const selectFullInstructors = (state) =>
  state?.treinamento?.full_instructors;
export const selectStatus = (state) => state?.treinamento?.status;
export const selectFilterData = (state) => state?.treinamento?.dataFilter;
export const selectFilters = (state) => state?.treinamento?.filter;

// Recupera os cursos com formatacao
export const selectCourses = createSelector(selectTrainningCouse, (items) => {
  if (!items) return [];
  return items.map((item) => [item.id, item.descricao]);
});

// Recupera os cursos sem formatacao
export const selectCoursesWithoutFormatting = createSelector(
  selectTrainningCouse,
  (items) => {
    if (!items) return [];
    return items.map((item) => item);
  }
);

// Seletor de instrutores
export const selectInstructors = createSelector(
  selectTrainningInstructor,
  (items) => {
    if (!items) return [];

    return items.map((item) => item);
  }
);
// Seletor de tipos de cursos
export const selectTypeOfCourses = createSelector(
  selectTrainningTypeOfCourse,
  (items) => {
    if (!items) return [];

    return items.map((item) => [item.id, item.descricao]);
  }
);
// Seletor de eficacia
export const selectEfficiency = createSelector(
  selectTrainningEfficiency,
  (items) => {
    if (!items) return [];

    return items.map((item) => [item.id, item.descricao]);
  }
);
// Seletor de desenvolvimento
export const selectDevelop = createSelector(selectTrainningDevelop, (items) => {
  if (!items) return [];

  return items.map((item) => [item.id, item.descricao]);
});

// Seletor de aplicaveis
export const selectApply = createSelector(selectTrainningApply, (items) => {
  if (!items) return [];

  return items.map((item) => [item.id, item.descricao]);
});

///
export const seletorIsInstructor = createSelector(
  selectTrainningInstructor,
  selectIsMat,
  (instructors, matricula) => {
    if (!instructors) return false;
    return instructors?.some(
      (instructor) => instructor[1].split("-")[0].trim() === matricula
    );
  }
);
