import React from "react";
import { Container } from "@mui/material";
import BackgroundRouter from "../background-router";

export default function GestaoTickets() {
  const _html =
    '<iframe title="gestao_tickets" style="width:100%; height:calc(100vh - 80px)" src="https://app.powerbi.com/view?r=eyJrIjoiYzA1ZDU1MDMtNmMyYi00YmU3LTk5NGEtM2MyYWI3YTQ1NjgzIiwidCI6IjgwMTNjOTFhLTRjNzktNDc5ZC1iMjYxLWJjZDNhMDRkM2I5MSJ9" frameborder="0" allowFullScreen="true"></iframe>';
  return (
    <BackgroundRouter>
      <Container sx={{ mt: 1 }} maxWidth={false}>
        <div dangerouslySetInnerHTML={{ __html: _html }} />
      </Container>
    </BackgroundRouter>
  );
}
