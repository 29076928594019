import { Button, Stack } from "@mui/material";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { Icone } from "../../../../components";

const STR = {
  titleEscalonateGoBack: "Voltar",
  labelEscalonateGoBack: "Voltar",
};
export const EscalonadaGoBack = () => {
  const history = useHistory();

  return (
    <Stack direction="row" sx={{ my: 1 }} justifyContent="space-between">
      <Button
        color="primary"
        title={STR.titleEscalonateGoBack}
        onClick={() => history.goBack()}
        startIcon={<Icone icone="Undo" />}
        variant="text"
      >
        {STR.labelEscalonateGoBack}
      </Button>
      <div />
    </Stack>
  );
};

export default EscalonadaGoBack;
