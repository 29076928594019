import React from "react";
import { format, parseISO } from "date-fns";
import { H6 } from "../../../../components";

const STR = {
  titlePageEscalonateCalendarDay: "ESCALONADAS DO DIA ",
};

function EscalonadaCalendarioTitulo({ day }) {
  return (
    <H6>
      {STR.titlePageEscalonateCalendarDay}{" "}
      {day && format(parseISO(`${day}`), "dd/MM/yyyy")}
    </H6>
  );
}

export default EscalonadaCalendarioTitulo;
