import React, { useEffect } from "react";
import { useFetch } from "../../../../hooks";
import { ToastErro } from "../../../../utils/utils";
import { Pesquisando } from "../../../../components";

const ROUTE = ["/escalonada_questionario_api"];

function EscalonadaQuestionarioRecuperar({ id, verRespostas, render }) {
  const { data, error, setFetch, wait } = useFetch(ROUTE[0], "GET");

  useEffect(() => {
    if (error) {
      ToastErro(error);
    }
  }, [error]);

  useEffect(() => {
    setFetch({ id, ver_respostas: verRespostas || false });
  }, [id, setFetch, verRespostas]);

  return <>{wait ? <Pesquisando /> : data ? render(data) : null}</>;
}

export default EscalonadaQuestionarioRecuperar;
