import React from "react";
import { Container } from "@mui/material";
import BackgroundRouter from "../background-router";

export default function Workload() {
  const _html =
    '<iframe title="workload" style="width:100%; height:calc(100vh - 80px)" src="https://app.powerbi.com/view?r=eyJrIjoiNjNkOWU3M2UtZjZmNS00ZmY2LThmYjQtZDI4Zjc1ZTU5NTc4IiwidCI6IjgwMTNjOTFhLTRjNzktNDc5ZC1iMjYxLWJjZDNhMDRkM2I5MSJ9" frameborder="0" allowFullScreen="true"></iframe>';
  return (
    <BackgroundRouter>
      <Container sx={{ mt: 1 }} maxWidth={false}>
        <div dangerouslySetInnerHTML={{ __html: _html }} />
      </Container>
    </BackgroundRouter>
  );
}
