import React, { useCallback, useEffect, useState } from "react";
import BackgroundRouter from "../../background-router";
import {
  AnimacaoSemDados,
  Body2,
  H6,
  Pesquisando,
  ScrollInfinito,
} from "../../../components";
import {
  trainningInit,
  trainningPage,
  trainningSetFilter,
} from "./treinamento-actions";
import {
  selectFilterData,
  selectFilters,
  selectStatus,
  selectTrainningData,
  selectTrainningItems,
  seletorIsInstructor,
} from "./treinamento-selectors";
import { useDispatch, useSelector } from "react-redux";
import { useToggle } from "react-use";
import {
  Box,
  Container,
  Divider,
  Fab,
  Grow,
  List,
  Pagination,
  Stack,
  useTheme,
} from "@mui/material";

import { selectIsRH } from "../../../utils/global-selectors";
import TrainningItem from "./components/trainning-item";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import TrainningSideBar, {
  getOptionsIsRH,
} from "./components/trainning-side-bar";
import TrainningModalViewer from "./components/trainning-modal-viewer";
import TrainningFilterStatus from "./components/trainning-filter-status";
import TrainningClearFilter from "./components/trainning-clear-filter";
import { useTranslation } from "react-i18next";
import { getStatusName } from "./treinamento-utils";

const Treinamento = () => {
  const isRH = useSelector(selectIsRH);
  const isInstructor = useSelector(seletorIsInstructor);
  const isMobile = useTheme()?.isMobile;
  const dispatch = useDispatch();
  const { t } = useTranslation();

  useEffect(() => {
    dispatch(trainningInit());
  }, [dispatch]);

  return (
    <BackgroundRouter>
      <TrainningModalViewer />

      {!isMobile && <TrainningSideBar />}
      <Container maxWidth={false}>
        <H6>{t("treinamento.titlePage")}</H6>
        {isMobile && (isRH || isInstructor) && <FabOrButton t={t} />}
        <BodyTrainning t={t} />
      </Container>
    </BackgroundRouter>
  );
};

const FabOrButton = ({ t }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const isMobile = useTheme()?.isMobile;
  const sxFab = {};
  if (isMobile) {
    sxFab.position = "fixed";
    sxFab.right = 16;
    sxFab.bottom = 72;
  } else {
    sxFab.mb = 1;
  }
  const options = getOptionsIsRH(dispatch, history, false, t);

  const newTrainning = options[0];

  return (
    <Stack direction="row-reverse">
      <Fab
        onClick={newTrainning.onClick}
        title={newTrainning.title}
        color="success"
        sx={sxFab}
        variant={isMobile ? "circular" : "extended"}
      >
        {newTrainning.icon}
        {!isMobile && <Body2>{t("treinamento.newTrainningText")}</Body2>}
      </Fab>
    </Stack>
  );
};

const BodyTrainning = ({ t }) => {
  const dispatch = useDispatch();
  const [wait, setWait] = useToggle(false);
  const trainnings = useSelector(selectTrainningItems);
  const allFilterData = useSelector(selectFilterData);
  const data = useSelector(selectTrainningData);

  useEffect(() => {
    dispatch(trainningPage(1, setWait));
  }, [dispatch, setWait]);

  let dataRegister = trainnings;

  if (allFilterData) {
    dataRegister = allFilterData;
  }

  return (
    <>
      {wait ? (
        <Pesquisando />
      ) : (
        <>
          {data && <TrainningPanel t={t} />}
          <br />
          {data && !allFilterData && <PaginationTrainning t={t} />}
          {dataRegister?.length === 0 ? (
            <AnimacaoSemDados titulo={t("treinamento.trainningNoData")} />
          ) : !allFilterData ? (
            <List>
              {dataRegister?.map((item, index) => (
                <TrainningItem key={index} item={item} />
              ))}
            </List>
          ) : (
            <List sx={{ p: 1 }}>
              <ScrollInfinito
                tamanho="70vh"
                itens={allFilterData}
                render={(item) => <TrainningItem key={item.id} item={item} />}
              />
            </List>
          )}
        </>
      )}
    </>
  );
};

const PaginationTrainning = ({ t }) => {
  const [page, setPage] = useState(1);
  const isMobile = useTheme()?.isMobile;
  const dispatch = useDispatch();
  const { total_paginas } = useSelector(selectTrainningData);

  const onChangePagina = useCallback(
    (evt, pageID) => {
      dispatch(trainningPage(pageID, () => {}));
      setPage(pageID);
    },
    [dispatch, setPage]
  );

  let totalPage;

  totalPage = total_paginas;

  return (
    <Stack>
      <Divider sx={{ mb: 1 }} />
      <Stack direction="row" alignItems="center">
        <Pagination
          size={isMobile ? "small" : "medium"}
          title={t("treinamento.titlePaginationFilter")}
          color="primary"
          count={totalPage}
          page={page}
          onChange={onChangePagina}
        />
      </Stack>
      <Divider sx={{ mt: 1 }} />
    </Stack>
  );
};

const TrainningPanel = ({ t }) => {
  const dispatch = useDispatch();
  const allStatus = useSelector(selectStatus);
  const allFilters = useSelector(selectFilters);
  const onFilter = useCallback(
    (st) => {
      dispatch(trainningSetFilter({ status: st }));
    },
    [dispatch]
  );

  return (
    <Stack alignItems="center" gap={1}>
      <Stack direction="row" gap={1} justifyContent="center">
        {allStatus?.map((status) => (
          <TrainningFilterStatus
            key={status.id}
            status={status.id}
            statusName={getStatusName(status.id, t)}
            onClick={() => onFilter(status.id)}
            isSelected={allFilters?.status === status.id}
            total={status.total}
          />
        ))}
      </Stack>
      <Grow in={Boolean(allFilters)} unmountOnExit>
        <Box>
          <TrainningClearFilter />
        </Box>
      </Grow>
    </Stack>
  );
};

Treinamento.rota = "/treinamento";

export default Treinamento;
