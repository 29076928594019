import { Stack } from "@mui/material";
import React, { useCallback } from "react";
import { EntradaForm, H6 } from "../../../../components";
import * as yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { useToggle } from "react-use";
import {
  selectorAuditFormat,
  selectorColabFormat,
} from "../escalonada-selectors";
import { obterValidador, VALIDADOR_TIPO } from "../../../../utils/validadores";
import { prizeDrawEscalonateAuditorPut } from "../escalonada-actions";

const STR = {
  titleEscalonateAuditorForm: "Adicionar auditor",
  labelMat: "Nome do auditor",
  labelAudit: "Nível de auditoria",
  errorMat: "Selecione um auditor",
  errorAudit: "Selecione um nível de auditoria",
};

const KEYS = {
  idAudit: "id_auditoria",
  mat: "matricula",
  plant: "planta",
};

function EscalonadaAuditorFormAdd() {
  return (
    <Stack>
      <H6>{STR.titleEscalonateAuditorForm}</H6>
      <EscalonadaAuditorForm />
    </Stack>
  );
}

const EscalonadaAuditorForm = () => {
  const [wait, setWait] = useToggle();
  const dispatch = useDispatch();
  const audits = useSelector(selectorAuditFormat);
  const colabs = useSelector(selectorColabFormat);

  const schema = [
    {
      type: "select",
      name: KEYS.mat,
      label: STR.labelMat,
      required: true,
      autoFormat: true,
      itens: colabs,
    },
    {
      type: "select",
      itens: audits,
      name: KEYS.idAudit,
      label: STR.labelAudit,
      required: true,
      autoFormat: true,
    },
  ];
  const schemaValidator = yup.object().shape({
    [KEYS.mat]: obterValidador(VALIDADOR_TIPO.selectUnico),
    [KEYS.idAudit]: obterValidador(VALIDADOR_TIPO.selectUnico),
  });
  const schemaMessageError = {
    [KEYS.mat]: STR.errorMat,
    [KEYS.idAudit]: STR.errorAudit,
  };

  const onSubmit = useCallback(
    (val) => {
      const obj = {
        [KEYS.idAudit]: parseInt(val[KEYS.idAudit].value),
        [KEYS.mat]: val[KEYS.mat].value.split("_")[0],
        [KEYS.plant]: val[KEYS.mat].value.split("_")[1],
      };
      dispatch(prizeDrawEscalonateAuditorPut(obj, setWait));
    },
    [setWait, dispatch]
  );

  return (
    <EntradaForm
      schema={schema}
      schemaValidator={schemaValidator}
      schemaMessageError={schemaMessageError}
      wait={wait}
      onSubmit={onSubmit}
    />
  );
};

export default EscalonadaAuditorFormAdd;
