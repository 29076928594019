import axios from "axios";
import { ToastErro } from "../../../utils/utils";
//
export const getStatusColor = (status) => {
  switch (status) {
    case "A":
      return "success";
    case "B":
      return "info";
    default:
      return "warning";
  }
};
export const getStatusIcon = (status) => {
  switch (status) {
    case "A":
      return "Timeline";
    case "B":
      return "Build";
    default:
      return "Help";
  }
};

export const getStatusName = (status) => {
  switch (status) {
    case "A":
      return "Aberto";
    case "B":
      return "Em vistoria";
    default:
      return "Pendente";
  }
};

export const onDownload = async (inRoute, data, setBtnWait) => {
  setBtnWait();
  const params = new URLSearchParams(data);

  const url = `${inRoute}?${params.toString()}`;
  try {
    const response = await axios.get(url);
    if (response.status === 200) {
      const a = document.createElement("a");
      a.href = response.data.arquivo;
      a.target = "_blank";
      a.setAttribute(
        "download",
        response.data.arquivo.substring(
          response.data.arquivo.lastIndexOf("/") + 1
        )
      );
      a.click();
      return true;
    } else {
      ToastErro("Erro ao gerar arquivo");
      return false;
    }
  } catch (error) {
    if (error?.response?.data?.message) {
      ToastErro(error?.response?.data?.message);
    } else if (error?.message) {
      ToastErro(error?.message);
    } else {
      ToastErro(String(error));
    }
    return false;
  } finally {
    setBtnWait();
  }
};
