import React, { useEffect } from "react";
import BackgroundRouter from "../../background-router";
import { Stack } from "@mui/material";
import {
  AnimacaoSemDados,
  Body1,
  Icone,
  Pesquisando,
  Tab,
} from "../../../components";
import { useDispatch, useSelector } from "react-redux";
import {
  isAdminEscalonada,
  selectPrizeDraw,
  selectPrizeDrawModal,
} from "./escalonada-selectors";
import { prizeDrawCloseModal, prizeDrawInit } from "./escalonada-actions";
import { useToggle } from "react-use";
import { DrawerDialog } from "../../../extra-components";
import EscalonadaModal from "./escalonada-modal";
import EscalonadaCadastro from "./cadastro/escalonada-cadastro";
import EscalonadaCalendario from "./calendario/escalonada-calendario";
import EscalonadaAuditor from "./auditores/escalonada-auditor";

const STR = {
  labelTab1: "Cadastro",
  labelTab2: "Calendário",
  labelTab3: "Auditores",
};

const Escalonada = () => {
  const data = useSelector(selectPrizeDraw);
  const [wait, setWait] = useToggle();
  const dispatch = useDispatch();

  const modal = useSelector(selectPrizeDrawModal);
  const closeModal = () => dispatch(prizeDrawCloseModal());

  useEffect(() => {
    dispatch(prizeDrawInit(setWait));
  }, [dispatch, setWait]);

  return (
    <BackgroundRouter>
      {modal && (
        <DrawerDialog
          fecharModal={closeModal}
          fnGetCorpo={() => <EscalonadaModal modal={modal} />}
        />
      )}
      {wait ? <Pesquisando /> : data ? <EscalonadaTab /> : <AnimacaoSemDados />}
    </BackgroundRouter>
  );
};

const EscalonadaTab = () => {
  const isAdminEscalonate = useSelector(isAdminEscalonada);
  let header = [{ label: STR.labelTab2, icon: "CalendarToday" }];
  const body = [<EscalonadaCalendario />];

  if (isAdminEscalonate) {
    header.splice(0, 0, { label: STR.labelTab1, icon: "Timeline" });
    header.push({ label: STR.labelTab3, icon: "Engineering" });
    body.splice(0, 0, <EscalonadaCadastro />);
    body.push(<EscalonadaAuditor />);
  }

  header = header.map((ele, idx) => (
    <Stack direction="row" alignItems="center" key={idx}>
      <Icone icone={ele.icon} />
      <Body1>{ele.label}</Body1>
    </Stack>
  ));

  return <Tab cabe={header} corpo={body} />;
};

Escalonada.rota = "/escalonada";

export default Escalonada;
