import React, { useCallback, useEffect } from "react";
import { useFetch } from "../../../../hooks";
import { ToastErro } from "../../../../utils/utils";
import { Pesquisando, QuestionForm } from "../../../../components";
import _ from "lodash";
import EscalonadaQuestionarioRecuperar from "./escalonada-questionario-recuperar";

const ROUTE = ["/escalonada_questionario_api"];

function EscalonadaQuestionarioResponder({ id }) {
  return (
    <>
      <EscalonadaQuestionarioRecuperar
        id={id}
        render={(data) => (
          <QuestionarioParaEscalonada id={id} questions={data} />
        )}
      />
    </>
  );
}

const QuestionarioParaEscalonada = ({ questions, id }) => {
  const { wait, setFetch, error, data } = useFetch(ROUTE[0], "POST", "JSON");

  useEffect(() => {
    if (error) {
      ToastErro(error);
    }
  }, [error]);
  //
  useEffect(() => {
    if (data) {
      window.location.reload();
    }
  }, [data]);

  const onResponseQuestion = useCallback(
    (response) => {
      const responsesNotNull = [];
      _.keys(response).forEach((idQUestion) => {
        _.keys(response[idQUestion]).forEach((idAsk) => {
          if (response[idQUestion][idAsk]) {
            responsesNotNull.push({
              id_pergunta: idAsk,
              id_questionario: idQUestion,
              resposta: response[idQUestion][idAsk].toString(),
            });
          }
        });
      });
      setFetch({ id, respostas: responsesNotNull });
    },
    [setFetch, id]
  );

  return (
    <>
      {wait ? (
        <Pesquisando />
      ) : (
        <QuestionForm
          questions={questions?.questionarios}
          onFinishQuestions={onResponseQuestion}
        />
      )}
    </>
  );
};

export default EscalonadaQuestionarioResponder;
