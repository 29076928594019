import React from "react";
import SidebarPage from "../../../../components/sidebar-page";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { Icone } from "../../../../components";
import { green } from "@mui/material/colors";
import { trainningSetModal } from "../treinamento-actions";
import TreinamentoModal from "../treinamento-modal";
import TreinamentoCursos from "../treinamento-cursos";
import TreinamentoInstrutor from "../treinamento-instrutor";
import { useTranslation } from "react-i18next";
import { selectIsRH } from "../../../../utils/global-selectors";
import { seletorIsInstructor } from "../treinamento-selectors";

const getOptionsAdd = (dispatch, t) => {
  return [
    {
      icon: <Icone icone="Add" />,
      text: t("treinamento.optionTextNewTrainning"),
      title: t("treinamento.optionTitleNewTrainning"),
      background: green[700],
      foreground: "white",
      onClick: () => {
        dispatch(
          trainningSetModal({
            type: TreinamentoModal.modal.FORM_ADD_TRAINNING,
          })
        );
      },
    },
  ];
};

export const getOptionsIsRH = (dispatch, history, isGoBack, t) => {
  const options = [
    ...getOptionsAdd(dispatch, t),
    {
      icon: <Icone icone="School" />,
      text: t("treinamento.optionTextMaintenceCouse"),
      title: t("treinamento.optionTitleMaintenceCouse"),
      background: "#1565c0",
      foreground: "white",
      onClick: () => history.push(TreinamentoCursos.rota),
    },
    {
      icon: <Icone icone="Engineering" />,
      text: t("treinamento.optionTextProfessor"),
      title: t("treinamento.optionTitleProfessor"),
      background: "#f57c00",
      foreground: "white",
      onClick: () => history.push(TreinamentoInstrutor.rota),
    },
    {
      icon: <Icone icone="Download" />,
      text: t("treinamento.optionTextDownloadExcel"),
      title: t("treinamento.optionTitleDownloadExcel"),
      background: "#2e7d32",
      foreground: "white",
      onClick: () => {
        dispatch(
          trainningSetModal({
            type: TreinamentoModal.modal.DOWNLOAD_TO_EXCEL,
          })
        );
      },
    },
  ];

  if (isGoBack) {
    options[0] = {
      icon: <Icone icone="ArrowBack" />,
      text: t("treinamento.goBackText"),
      title: t("treinamento.goBackTitle"),
      background: "#f44336",
      foreground: "white",
      onClick: () => history.goBack(),
    };
  }

  return options;
};

const getOptionsIsInstructor = (dispatch, history, isGoBack, t) => {
  const options = [...getOptionsAdd(dispatch, t)];

  if (isGoBack) {
    options[0] = {
      icon: <Icone icone="ArrowBack" />,
      text: t("treinamento.goBackText"),
      title: t("treinamento.goBackTitle"),
      background: "#f44336",
      foreground: "white",
      onClick: () => history.goBack(),
    };
  }
  return options;
};

function TrainningSideBar({ isGoBack }) {
  const isRH = useSelector(selectIsRH);
  const isInstructor = useSelector(seletorIsInstructor);
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation();
  let options = [];
  if (isRH) {
    options = getOptionsIsRH(dispatch, history, isGoBack, t);
  } else if (isInstructor) {
    options = getOptionsIsInstructor(dispatch, history, isGoBack, t);
  }
  return (
    <SidebarPage options={options} barColor="#e3f2fd" maxWidthOpen="20%" />
  );
}

export default TrainningSideBar;
