import React, { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import {
  selectPrizeDrawArea,
  selectPrizeDrawAreaXModel,
  selectPrizeDrawClassification,
} from "./escalonada-selectors";
import { obterValidador, VALIDADOR_TIPO } from "../../../utils/validadores";

import * as yup from "yup";
import { Stack } from "@mui/material";
import { EntradaForm, H6 } from "../../../components";
import { useToggle } from "react-use";
import { prizeDrawAddUpd } from "./escalonada-actions";
import { format } from "date-fns";
//
const STR = {
  titleFormAdd: "Adicionar",
  titleFormUpd: "Editar",
  labelaArea: "Área",
  placeholderArea: "Selecione a área",
  labelModel: "Modelo",
  placeholderModel: "Selecione o modelo",
  placeholderValue: "Digite o valor",
  errorArea: "Seleciona a área",
  errorModel: "Seleciona ao menos um modelo",
  labelDateRegister: "Data de Registro",
  placeholderDateRegister: "Digite a data de registro",
  errorDateRegister: "Digite a data de registro",
};

const KEYS = {
  area: "area",
  model: "modelos",
  classification: "classificacao",
  date_register: "data_registro",
};

const formatAreaDefault = (area, areaList) => {
  const areaSelected = areaList.filter((item) => item[0] === area);
  return { value: areaSelected[0][0], label: areaSelected[0][1], key: area };
};
const formatClassificationDefault = (classificationId, classificationsList) => {
  const classificationSelected = classificationsList.filter(
    (item) => item.id === classificationId
  );
  return classificationSelected[0].quantidade;
};

function EscalonadaAddUpd({ item }) {
  const [wait, setWait] = useToggle();
  const dispatch = useDispatch();
  const selectAreas = useSelector(selectPrizeDrawArea);
  const selectAreaXModel = useSelector(selectPrizeDrawAreaXModel);
  const selectClassification = useSelector(selectPrizeDrawClassification);
  //
  // Funcao de callback que recebe o id_area e aponta os modelos a escolher
  const fnGetModels = useCallback(
    (area) => {
      return selectAreaXModel?.hasOwnProperty(area?.value)
        ? selectAreaXModel[area?.value]
        : [];
    },
    [selectAreaXModel]
  );

  let schema = [
    {
      type: "date",
      name: KEYS.date_register,
      label: STR.labelDateRegister,
      placeholder: STR.placeholderDateRegister,
      defaultValue: item ? item.data_registro : null,
      disabled: item ? true : false,
      extraProps: {
        inputProps: {
          max: format(new Date(), "yyyy-MM-dd"),
        },
      },
    },
    {
      type: "select",
      name: KEYS.area,
      label: STR.labelaArea,
      placeholder: STR.placeholderArea,
      itens: selectAreas,
      disabled: item ? true : false,
      autoFormat: true,
      defaultValue: item ? formatAreaDefault(item.area, selectAreas) : null,
    },
    {
      type: "select",
      name: KEYS.model,
      label: STR.labelModel,
      itens: item ? selectAreaXModel[item.area] : [],
      autoFormat: true,
      defaultValue: item ? item.modelos : null,
      isMulti: true,
      disabled: item ? true : false,
      exibirSe: item ? null : fnGetModels,
    },
  ];
  const schemaMessageError = {
    [KEYS.area]: STR.errorArea,
    [KEYS.model]: STR.errorModel,
    [KEYS.date_register]: STR.errorDateRegister,
  };
  const schemaValidator = {
    [KEYS.area]: obterValidador(VALIDADOR_TIPO.selectUnico),
    [KEYS.model]: obterValidador(VALIDADOR_TIPO.selectMultiplo),
    [KEYS.date_register]: obterValidador(VALIDADOR_TIPO.data),
  };

  /// Inflar com todas as possíveis clasificações
  schema = schema.concat(
    selectClassification.map((innerItem) => {
      const keyField = `${KEYS.classification}_${innerItem[0]}`;
      schemaValidator[keyField] = yup.number().min(0).required();
      schemaMessageError[keyField] =
        "O valor deve ter no mínimo 0, não são aceitos valores negativos";

      return {
        type: "number",
        name: `${KEYS.classification}_${innerItem[0]}`,
        label: innerItem[1],
        placeholder: STR.placeholderValue,
        defaultValue: item
          ? formatClassificationDefault(innerItem[0], item.classificacao)
          : 0,
      };
    })
  );

  const onSubmit = useCallback(
    (val) => {
      const obj = {};
      const keysClassifications = _.keys(val).filter(
        (key) => key.search(KEYS.classification) !== -1
      );

      obj[KEYS.area] = parseInt(val[KEYS.area].value);
      obj[KEYS.model] = val[KEYS.model].map((item) => parseInt(item.value));
      obj[KEYS.classification] = keysClassifications.map((key) => {
        return { [key.split("_")[1]]: parseInt(val[key]) };
      });
      obj[KEYS.date_register] = format(val[KEYS.date_register], "yyyy-MM-dd");

      if (item) {
        obj.id = item.id;
        delete obj[KEYS.area];
        delete obj[KEYS.model];
      }

      dispatch(prizeDrawAddUpd(obj, setWait));
    },
    [dispatch, item, setWait]
  );

  return (
    <Stack>
      <H6>{STR.titleFormAdd}</H6>
      <EntradaForm
        schema={schema}
        schemaMessageError={schemaMessageError}
        schemaValidator={yup.object().shape(schemaValidator)}
        onSubmit={onSubmit}
        wait={wait}
        exibirSe={item ? null : { ouvir: KEYS.area, atualizar: KEYS.model }}
      />
    </Stack>
  );
}

export default EscalonadaAddUpd;
