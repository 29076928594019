import React, { useCallback } from "react";
import * as yup from "yup";
import { format, parseISO, addMonths } from "date-fns";
import { useToggle } from "react-use";
import { Stack } from "@mui/material";
import { EntradaForm, H6 } from "../../../components";
import { obterValidador, VALIDADOR_TIPO } from "../../../utils/validadores";
import { useDispatch } from "react-redux";
import { prizeDrawCloseModal } from "./escalonada-actions";
import { onDownload } from "./escalonada-utils";

const ROTAS = ["/escalonada_gerar_excel_api"];

const STR = {
  titlePageExcel:
    "Escolha o periodo em que se deseja baixar o arquivo em Excel",
};
const KEYS = {
  periodos: "periodos",
};

const generate_period_year_month = (per_init, per_final) => {
  const [year_init, month_init] = per_init.split("-");
  const period_final =
    per_final ||
    `${format(new Date(), "yyyy-MM-01")}_${format(new Date(), "yyyy-MM-dd")}`;
  const period_initial = `${format(parseISO(per_init), "yyyy-MM-01")}_${format(
    new Date(year_init, month_init, 0),
    "yyyy-MM-dd"
  )}`;

  let day_initial = parseISO(period_initial.split("_")[0]);
  const periods = [[period_initial, period_initial]];

  while (day_initial < parseISO(period_final.split("_")[1])) {
    const day_final = addMonths(day_initial, 1);
    if (day_final > new Date()) {
      break;
    }
    const [year_final, month_final] = format(day_final, "yyyy-MM-dd").split(
      "-"
    );
    periods.push([
      `${format(day_final, "yyyy-MM-dd")}_${format(
        new Date(year_final, month_final, 0),
        "yyyy-MM-dd"
      )}`,
      `${format(day_final, "yyyy-MM-dd")}_${format(
        new Date(year_final, month_final, 0),
        "yyyy-MM-dd"
      )}`,
    ]);
    day_initial = day_final;
  }
  return periods;
};

function EscalonadaBaixarExcel() {
  const dispatch = useDispatch();
  const [wait, setWait] = useToggle();
  const periods = generate_period_year_month("2024-09-01");

  const schema = [
    {
      type: "select",
      name: KEYS.periodos,
      label: "Período",
      itens: periods,
      autoFormat: true,
    },
  ];

  const schemaMessageError = {
    [KEYS.periodos]: "O período deve ser selecionado",
  };

  const schemaValidator = yup.object().shape({
    [KEYS.periodos]: obterValidador(VALIDADOR_TIPO.selectUnico),
  });

  const onSubmit = useCallback(
    async (val) => {
      const [dateFrom, dateTo] = val[KEYS.periodos].value.split("_");
      const data = { de: dateFrom, ate: dateTo };
      const isOk = await onDownload(ROTAS[0], data, setWait);
      if (isOk) {
        dispatch(prizeDrawCloseModal());
      }
    },
    [setWait, dispatch]
  );

  return (
    <Stack>
      <H6>{STR.titlePageExcel}</H6>
      <EntradaForm
        schema={schema}
        onSubmit={onSubmit}
        schemaValidator={schemaValidator}
        schemaMessageError={schemaMessageError}
        wait={wait}
      />
    </Stack>
  );
}

export default EscalonadaBaixarExcel;
