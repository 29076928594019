import { Stack, useTheme } from "@mui/material";
import React from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import EscalonadaGoBack from "../components/escalonada-go-back";
import EscalonadaCalendarioLista from "./escalonada-calendario-lista";
import EscalonadaCalendarioTitulo from "./escalonada-calendario-titulo";

function EscalonadaCalendarioVerDia({ escalonates, escalonateDay }) {
  const isMobile = useTheme()?.isMobile;
  const history = useHistory();

  let escalonateView = [],
    escalonateDayView = "";

  if (history.location?.state) {
    escalonateView = history.location.state.escalonates;
    escalonateDayView = history.location.state.escalonateDay;
  } else {
    escalonateView = escalonates;
    escalonateDayView = escalonateDay;
  }

  return (
    <>
      <Stack
        direction="row"
        justifyContent={{ xs: "center", md: "space-between" }}
      >
        {!isMobile && <EscalonadaGoBack />}
        <EscalonadaCalendarioTitulo day={escalonateDayView} />
        {!isMobile && <div />}
      </Stack>
      <EscalonadaCalendarioLista escalonates={escalonateView} />
    </>
  );
}

EscalonadaCalendarioVerDia.rota = "/escalonada_calendario";

export default EscalonadaCalendarioVerDia;
