import { useCallback } from "react";
import { useDispatch } from "react-redux";
import { prizeDrawSetModal } from "../escalonada-actions";
import EscalonadaModal from "../escalonada-modal";
import OptionsMenu from "../../../../components/options-menu";

const STR = {
  titleViewPrizeDraw: "VISUALIZAR",
  labelViewPrizeDraw: "VISUALIZAR",
  titleEditPrizeDraw: "EDITAR",
  labelEditPrizeDraw: "EDITAR",
  titleDeletePrizeDraw: "EXCLUIR",
  labelDeletePrizeDraw: "EXCLUIR",
};

const PrizeDrawOptionsCad = ({ id, item }) => {
  const dispatch = useDispatch();
  //
  const onDelete = useCallback(() => {
    dispatch(
      prizeDrawSetModal({
        type: EscalonadaModal.modal.DEL,
        data: id,
      })
    );
  }, [dispatch, id]);
  //
  const onView = useCallback(() => {
    dispatch(
      prizeDrawSetModal({
        type: EscalonadaModal.modal.VIEW,
        data: item,
      })
    );
  }, [dispatch, item]);
  //
  const onEdit = useCallback(() => {
    dispatch(
      prizeDrawSetModal({
        type: EscalonadaModal.modal.UPD,
        data: item,
      })
    );
  }, [dispatch, item]);

  const options = [
    {
      icon: "Visibility",
      title: STR.titleViewPrizeDraw,
      label: STR.labelViewPrizeDraw,
      onClick: onView,
    },
    {
      icon: "Edit",
      title: STR.titleEditPrizeDraw,
      label: STR.labelEditPrizeDraw,
      onClick: onEdit,
    },
    {
      icon: "Delete",
      title: STR.titleDeletePrizeDraw,
      label: STR.labelDeletePrizeDraw,
      onClick: onDelete,
    },
  ];

  return <OptionsMenu options={options} />;
};

export default PrizeDrawOptionsCad;
