import { Avatar, Box, Chip, Divider, Grid, Paper, Stack } from "@mui/material";
import React from "react";
import _ from "lodash";
import { Body1, H6 } from "../../../components";
import { format, parseISO } from "date-fns";
import { blue, green } from "@mui/material/colors";

const STR = {
  viewTitle: "Detalhes da escalonada",
  viewMat: "Criado por:",
  viewArea: "Área",
  viewModels: "Modelos",
  viewClassGeneral: "Classificação Geral",
  viewDateCreated: "Data de Criação",
  viewDateRegister: "Data de Registro",
  value: "Valor",
  viewPontuation: "Pontuação Total",
};

function EscalonadaVer({ item }) {
  const sxPaper = {
    p: 1,
  };
  const totalPontuation = _.sumBy(
    item.classificacao,
    (c) => c.quantidade * c.peso
  );

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <H6>{STR.viewTitle}</H6>
      </Grid>
      <Grid item xs={12} md={6}>
        <Paper sx={sxPaper}>
          <Stack direction="row" gap={1}>
            <Avatar alt={item.nome} src={item.avatar} />
            <Stack>
              <Body1 fontWeight="bold">{STR.viewMat}</Body1>
              <Body1>
                {item.matricula} - {item.nome}
              </Body1>
            </Stack>
          </Stack>
        </Paper>
      </Grid>
      <Grid item xs={12} md={6}>
        <Paper sx={sxPaper}>
          <Body1 fontWeight="bold">{STR.viewDateRegister}</Body1>
          <Body1>{format(parseISO(item.data_registro), "dd/MM/yyyy")}</Body1>
        </Paper>
      </Grid>
      <Grid item xs={12}>
        <Paper sx={sxPaper}>
          <Body1 fontWeight="bold">{STR.viewArea}</Body1>
          <Body1>{item.area_nome}</Body1>
        </Paper>
      </Grid>
      <Grid item xs={12}>
        <Paper sx={sxPaper}>
          <Body1 fontWeight="bold">{STR.viewModels}</Body1>
          <Stack direction="row" gap={1}>
            {item.modelos.map((m) => (
              <Chip label={m[1]} key={m[0]} color="primary" />
            ))}
          </Stack>
        </Paper>
      </Grid>
      <Grid item xs={12}>
        <Paper sx={sxPaper}>
          <Body1 textAlign="center" fontWeight="bold">
            {STR.viewClassGeneral}
          </Body1>
          <EscalonateClassificationView
            quantity={totalPontuation}
            title={STR.viewPontuation}
            color={green[300]}
            isDivider
          />
          <Stack gap={1}>
            {item.classificacao.map((c, idx) => (
              <EscalonateClassificationView
                key={c.id}
                quantity={c.quantidade * c.peso}
                title={c.nome}
                isDivider={idx + 1 < item.classificacao.length}
              />
            ))}
          </Stack>
        </Paper>
      </Grid>
    </Grid>
  );
}

const EscalonateClassificationView = ({
  color,
  quantity,
  title,
  isDivider,
}) => {
  return (
    <Stack>
      <Body1 fontWeight="bold">{title}</Body1>
      <Stack direction="row" gap={0.5} alignItems="baseline">
        <Body1>{STR.value}</Body1>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            background: color || blue[300],
            color: "black",
            borderRadius: "100%",
            width: "36px",
            height: "36px",
          }}
        >
          {quantity}
        </Box>
      </Stack>
      {isDivider && <Divider />}
    </Stack>
  );
};

export default EscalonadaVer;
