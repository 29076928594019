import {
  Avatar,
  Button,
  Chip,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
  Paper,
  Stack,
} from "@mui/material";
import { Body1, Icone } from "../../../../components";
import { format, parseISO } from "date-fns";
import OptionsMenu from "../../../../components/options-menu";
import { useHistory } from "react-router-dom";
import TreinamentoView from "../treinamento-view";
import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { trainningSetModal } from "../treinamento-actions";
import TreinamentoModal from "../treinamento-modal";
import TrainningStatusChip from "./trainning-status-chip";
import { selectIsRH } from "../../../../utils/global-selectors";
import { useTranslation } from "react-i18next";

const TrainningItem = ({ item }) => {
  const id = item.id;
  const { t } = useTranslation();

  return (
    <Paper sx={{ my: 1 }} elevation={3}>
      <ListItem>
        <ListItemAvatar>
          <Avatar alt={item.instrutor?.nome} src={item.instrutor?.avatar} />
        </ListItemAvatar>

        <ListItemText
          primary={
            <Stack direction="row" gap={1}>
              <Chip color="primary" size="small" label={item.codigo} />
              <Body1>
                {t("treinamento.trainningDateTraining")}{" "}
                {format(parseISO(item.data_treinamento), "dd/MM/yyyy")}
              </Body1>
            </Stack>
          }
          secondary={
            <Stack direction="column" alignItems="flex-start">
              <Body1>
                {t("treinamento.instructor")}: {item.instrutor?.nome}
              </Body1>
              <Body1>
                {t("treinamento.local")}: {item.local}
              </Body1>
              <TrainningItemOptions id={id} t={t} />
            </Stack>
          }
        />
        <ListItemSecondaryAction>
          <TrainningStatusChip status={item.status} />
        </ListItemSecondaryAction>
      </ListItem>
    </Paper>
  );
};

const TrainningItemOptions = ({ id, t }) => {
  const isRH = useSelector(selectIsRH);
  const dispatch = useDispatch();
  const history = useHistory();

  const onViewTrainning = useCallback(() => {
    const params = new URLSearchParams();
    params.append("id", id);
    history.push(`${TreinamentoView.rota}?${params.toString()}`);
  }, [history, id]);

  const onEditForm = useCallback(() => {
    dispatch(
      trainningSetModal({
        type: TreinamentoModal.modal.FORM_EDT_TRAINNING,
        data: id,
      })
    );
  }, [dispatch, id]);

  const onAddParticipants = useCallback(() => {
    dispatch(
      trainningSetModal({
        type: TreinamentoModal.modal.FORM_ADD_STUDENTS,
        data: id,
      })
    );
  }, [dispatch, id]);

  const onViewHistory = useCallback(() => {
    dispatch(
      trainningSetModal({
        type: TreinamentoModal.modal.VIEW_HISTORY,
        data: id,
      })
    );
  }, [dispatch, id]);

  const onAlterStatus = useCallback(() => {
    dispatch(
      trainningSetModal({
        type: TreinamentoModal.modal.ALTER_STATUS,
        data: id,
      })
    );
  }, [dispatch, id]);

  const onDeleteTrainning = useCallback(() => {
    dispatch(
      trainningSetModal({
        type: TreinamentoModal.modal.DELETE_TRAINNING,
        data: id,
      })
    );
  }, [dispatch, id]);

  const options = [
    {
      icon: "Visibility",
      title: t("treinamento.titleViewTrainning"),
      label: t("treinamento.labelViewTrainning"),
      onClick: onViewTrainning,
    },
    {
      icon: "Edit",
      title: t("treinamento.titleEditTrainning"),
      label: t("treinamento.labelEditTrainning"),
      onClick: onEditForm,
    },
    {
      icon: "Groups",
      title: t("treinamento.titleAddParticipant"),
      label: t("treinamento.labelAddParticipant"),
      onClick: onAddParticipants,
    },
    {
      icon: "History",
      title: t("treinamento.titleViewHistory"),
      label: t("treinamento.labelViewHistory"),
      onClick: onViewHistory,
    },
  ];

  if (isRH) {
    options.push({
      icon: "SwapVert",
      title: t("treinamento.titleAlterStatus"),
      label: t("treinamento.labelAlterStatus"),
      onClick: onAlterStatus,
    });
    options.push({
      icon: "Delete",
      title: t("treinamento.titleDeleteTrainning"),
      label: t("treinamento.labelDeleteTrainning"),
      onClick: onDeleteTrainning,
    });
  }

  return (
    <OptionsMenu
      component={
        <Button
          endIcon={<Icone icone="MoreVert" />}
          size="small"
          variant="contained"
        >
          {t("treinamento.labelBtnOptions")}
        </Button>
      }
      options={options}
    />
  );
};

export default TrainningItem;
