import { Stack } from "@mui/material";
import React, { useCallback } from "react";
import { Body1, EntradaForm, H6 } from "../../../../components";
import * as yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { useToggle } from "react-use";
import { selectorAuditFormat } from "../escalonada-selectors";
import { obterValidador, VALIDADOR_TIPO } from "../../../../utils/validadores";
import { prizeDrawEscalonateAuditorPut } from "../escalonada-actions";

const STR = {
  titleEscalonateAuditorFormEdit:
    "Escolha o novo nível de auditoria para este auditor",
  labelAudit: "Nível de auditoria",
  errorAudit: "Selecione um nível de auditoria",
};

const KEYS = {
  idAudit: "id_auditoria",
  mat: "matricula",
  plant: "planta",
};

function EscalonadaAuditorFormEdit({ idAudit, mat, plant, name }) {
  return (
    <Stack>
      <H6>{STR.titleEscalonateAuditorFormEdit}</H6>
      <Body1 textAlign="center">
        {mat} - {name}
      </Body1>
      <EscalonadaAuditorForm mat={mat} plant={plant} />
    </Stack>
  );
}

const EscalonadaAuditorForm = ({ mat, plant, idAudit }) => {
  const [wait, setWait] = useToggle();
  const dispatch = useDispatch();
  const audits = useSelector(selectorAuditFormat);

  const schema = [
    {
      type: "select",
      itens: audits,
      name: KEYS.idAudit,
      label: STR.labelAudit,
      defaultValue: idAudit,
      required: true,
      autoFormat: true,
    },
  ];
  const schemaValidator = yup.object().shape({
    [KEYS.idAudit]: obterValidador(VALIDADOR_TIPO.selectUnico),
  });
  const schemaMessageError = {
    [KEYS.idAudit]: STR.errorAudit,
  };

  const onSubmit = useCallback(
    (val) => {
      const obj = {
        [KEYS.idAudit]: parseInt(val[KEYS.idAudit].value),
        [KEYS.mat]: mat,
        [KEYS.plant]: plant,
      };
      dispatch(prizeDrawEscalonateAuditorPut(obj, setWait));
    },
    [setWait, dispatch, mat, plant]
  );

  return (
    <EntradaForm
      schema={schema}
      schemaValidator={schemaValidator}
      schemaMessageError={schemaMessageError}
      wait={wait}
      onSubmit={onSubmit}
    />
  );
};

export default EscalonadaAuditorFormEdit;
