import {
  Avatar,
  Box,
  Chip,
  Container,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Paper,
  Stack,
} from "@mui/material";
import React, { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Body2, FabExtend, Icone } from "../../../../components";
import { selectAudit, selectAuditors } from "../escalonada-selectors";
import Filtro from "../../../../components/input-busca";
import EscalonadaModal from "../escalonada-modal";
import { prizeDrawSetModal } from "../escalonada-actions";
import OptionsMenu from "../../../../components/options-menu";

const STR = {
  auditorLabelEdit: "Editar",
  auditorTitleEdit: "Editar auditor alterando o seu nível",
  auditorLabelDel: "Excluir",
  auditorTitleDel: "Excluir auditor",
  totalOfAuditors: "Total de auditores",
};

const filterFunction = (auditor, filter) => {
  return (
    auditor.nome.toLowerCase().includes(filter.toLowerCase()) ||
    auditor.matricula.toLowerCase().includes(filter.toLowerCase()) ||
    auditor.cargo.toLowerCase().includes(filter.toLowerCase()) ||
    auditor.nome_auditoria.toLowerCase().includes(filter.toLowerCase())
  );
};

function EscalonadaAuditor() {
  const [filter, setFilter] = React.useState("");
  return (
    <Container maxWidth="md">
      <EscalonadaAuditorPainel setFilter={setFilter} filterValue={filter} />

      <AuditoresList filterValue={filter}>
        <EscalonadaFiltroPorNivel setFilter={setFilter} filterValue={filter} />
      </AuditoresList>
    </Container>
  );
}

const EscalonadaAuditorPainel = ({ setFilter, filterValue }) => {
  const dispatch = useDispatch();

  const onAddAuditor = useCallback(() => {
    dispatch(
      prizeDrawSetModal({
        type: EscalonadaModal.modal.ADD_AUDITOR,
      })
    );
  }, [dispatch]);
  return (
    <Stack direction="row" alignItems="center" gap={1}>
      <Box flex={1}>
        <Filtro
          fullWidth
          sx={{ mb: 0 }}
          setFiltro={setFilter}
          filtro={filterValue}
        />
      </Box>
      <FabExtend color="success" onClick={onAddAuditor} />
    </Stack>
  );
};

const EscalonadaFiltroPorNivel = ({ setFilter, filterValue }) => {
  const audit = useSelector(selectAudit);

  return (
    <Stack direction="row" alignItems="center" gap={1}>
      {audit?.map((audit) => (
        <Box
          sx={{
            cursor: "pointer",
          }}
          key={audit.id}
          onClick={() =>
            setFilter((value) => (audit.nome === value ? null : audit.nome))
          }
        >
          <Chip
            label={audit.nome}
            key={audit.id}
            icon={filterValue === audit.nome ? <Icone icone="Check" /> : null}
            variant={filterValue === audit.nome ? "filled" : "outlined"}
            color="primary"
          />
        </Box>
      ))}
    </Stack>
  );
};

const AuditoresList = ({ filterValue, children }) => {
  const auditores = useSelector(selectAuditors);
  let bodyAuditors = auditores;
  if (filterValue) {
    bodyAuditors = auditores.filter((auditor) =>
      filterFunction(auditor, filterValue)
    );
  }
  return (
    <>
      <Stack direction="column">
        <Body2>
          {STR.totalOfAuditors}: {bodyAuditors?.length}
        </Body2>
        {children}
      </Stack>
      <List>
        {bodyAuditors?.map((auditor) => (
          <Paper key={`${auditor.matricula}_${auditor.planta}`} sx={{ my: 1 }}>
            <AuditorItem auditor={auditor} />
          </Paper>
        ))}
      </List>
    </>
  );
};

const AuditorItem = ({ auditor }) => {
  return (
    <ListItem secondaryAction={<AuditorItemOptions auditor={auditor} />}>
      <ListItemAvatar>
        <Avatar src={auditor.avatar} alt={auditor.nome} />
      </ListItemAvatar>
      <ListItemText
        primary={`${auditor.matricula} - ${auditor.nome}`}
        secondary={
          <Stack>
            <Body2>{auditor.cargo}</Body2>
            <Body2>{auditor.nome_auditoria}</Body2>
          </Stack>
        }
      />
    </ListItem>
  );
};

const AuditorItemOptions = ({ auditor }) => {
  const dispatch = useDispatch();

  const onEdit = useCallback(() => {
    dispatch(
      prizeDrawSetModal({
        type: EscalonadaModal.modal.EDIT_AUDITOR,
        data: auditor,
      })
    );
  }, [dispatch, auditor]);
  //
  const onDelete = useCallback(() => {
    dispatch(
      prizeDrawSetModal({
        type: EscalonadaModal.modal.DEL_AUDITOR,
        data: auditor,
      })
    );
  }, [dispatch, auditor]);

  const menus = [
    {
      label: STR.auditorLabelEdit,
      icon: "Edit",
      title: STR.auditorTitleEdit,
      onClick: onEdit,
    },
    {
      label: STR.auditorLabelDel,
      icon: "Delete",
      title: STR.auditorTitleDel,
      onClick: onDelete,
    },
  ];

  return <OptionsMenu options={menus} />;
};

export default EscalonadaAuditor;
