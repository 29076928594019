/**
 * Arquivo de traduções de strings de texto do sistema baseado no idioma informado.
 * Esta estrutura é padrão a todos os arquivos dentro desta pasta de deve seguir a
 * mesma sequencia de chave_valor dos demais.
 *
 * O padrão é link: { chave: valor }, sendo o "link" o nome da URL do menu em acesso
 * via lowercase.
 *
 * TRADUÇÃO ENGLISH
 */

const translate = {
  components: {
    calendario: {
      sunday: "Sunday",
      monday: "Monday",
      tuesday: "Tuesday",
      wednesday: "Wednesday",
      thursday: "Thursday",
      friday: "Friday",
      saturday: "Saturday",
      prev: "Back",
      next: "Next",
      beforeMonth: "Previous month",
      afterMonth: "Next month",
      today: "Today",
    },
    filtro_por_periodo: {
      titleApplyFilter: "Apply filter for data",
      fromToday: "from today.",
      lastDays: "of the last",
      days: "days",
      today: "Today",
      perPeriod: "Per period",
      filterApplied: "Filter applied in the period",
      filterAppliedErrors: "Filter applied for errors by defined period",
      titleClearFilter: "Clear applied filter",
      labelClearFilter: "Clear filter",
      labelFrom: "From",
      labelTo: "To",
      errorDateFrom: "* From date required",
      errorDateTo: "* To date required",
      title: "Choose between the from and to dates",
      subtitle:
        "Be careful not to choose the FROM date greater than the TO, otherwise your filter will be empty.",
    },

    filtro_avancado: {
      title: "Choose the type of filter to be applied",
    },
    row_paginate_template: {
      labelButtonAdd: "ADD",
      titlePagination: "Click to switch between pages",
      titleButtonOptions: "Click to display options",
      labelButtonOptions: "Options",
      titlePage: "Pagination template as record",
      titleButtonAdd: "Click to add",
    },
    aniversariantes: {
      title: "List of Birthdays",
      titleCompany: "Company's birthday list",
      erroGetBirthDay: "Error trying to retrieve birthdays",
      altImg: "Birthday image",
      today: "Today",
      labelBtnNext: "Next",
      greeting: "Give congratulations 🥳",
    },
    entrada_form: {
      labelBtnSend: "SEND",
    },
    drawer_dialog: {
      title: "Click to close",
      labelBtnClose: "CLOSE",
    },
    fab_extend: {
      title: "Click to include",
      text: "Add",
    },
    confirmar: {
      yes: "YES",
      no: "NO",
    },
  },
  lesson: {
    titleHeaderTable: "MASTER LIST OF LESSONS LEARNED",
    nameAndEmail: "NAME / EMAIL",
    dateCreated: "DATE OF CREATED",
    plant: "PLANT",
    pn: "PART NUMBER",
    descriptionPiece: "PART DESCRIPTION",
    categoryAnomaly: "ANOMALY CATEGORY",
    anomalyFound: "ANOMALY FOUND",
    identifyLL: "LL IDENTIFICATION",

    title: "LESSONS LEARNED CARD",
    labelButtonGoBack: "Back",
    titleButtonBack: "Return to the Lessons Learned page",
    labelDownloadPDF: "Download in PDF",
    titleButtonDownloadPdf: "Click to download PDF",
    placeholder: "Type here...",
    labelImpact: "PROJECT IMPACT",
    labelPlant: "PLANT",
    labelPN: "PART NUMBER",
    labelProcessUnit: "PROCESS UNIT",
    labelOperation: "OPERATION",
    labelLine: "PRODUCTION LINE",
    labelAreaAffected: "AFFECTED AREA",
    labelDate: "DATE",
    labelDescAnomaly: "ANOMALY DESCRIPTION",
    labelTitleEffect1: "WHAT ARE THE SYMPTOMS OF THE PROBLEM?",
    labelImageKO: "KO IMAGE",
    labelImageOK: "OK IMAGE",
    labelCauseRoot: "ROOT CAUSE",
    labelTitleDescriptionProblem: "5W2H PROBLEM DESCRIPTION",
    labelWhat: "What/ What happened?",
    labelWhy: "Why/ Why is it a problem? ",
    labelWhen: "When/ When did it happen ?",
    labelWhere: "Where/ Where has been detected ?",
    labelWho: "Who/ Who has detected it ?",
    labelHow: "How/ How has it been detected?",
    labelHowMany: "How many/ How many ?",
    labelTitleEffect: "EFFECT",
    labelProblemImage: "PROBLEM",
    labelSolutionImage: "SOLUTION",
    labelDescProblem: "PROBLEM DESCRIPTION",
    labelDesSolution: "SOLUTION DESCRIPTION",
    labelDrawingMathematicalImage: "MATHEMATICAL DESIGN",
    labelFabricant: "MANUFACTURER",
    labelTitleDocDefault: "UPDATING STANDARD DOCUMENTS",
    labelTableTitleDesc: "DESCRIPTION",
    labelTableTitleRevision: "NEED TO REVISE? - YES/NO",
    labelTableResponsible: "OWNER",
    labelTableData: "DATA",
    labelTableAccomplished: "REALIZED",
    labelObservation: "NOTE",
    labelCust: "COST",
    labelBenef: "BENEFIT",
    labelImageInsert: "Add image here",
    labelBtnAddEffect: "Add effect",
    labelBtnAddUpdateDoc: "CLICK HERE TO ADD",
    titleRemoveImageUpload: "Click to choose an image to upload",
    titleRemoveEffect: "Click to remove the effect",
    titleFormAddTypeUpdate: "FILL OUT THE FIELDS BELOW",
    errorListUpdate: "* Required field",
    errorRevision: "* Required field",
    errorResponsible: "* Required field",
    errorDate: "* Required field",
    errorAccomplished: "* Required field",
    errorObservation: "* Minimum 3 characters",
    labelBtnSend: "SEND",
    placeholderMoney: " 0,00",
    messageErrors: {
      idImpact: "* Choose a project impact",
      plant: "* Choose a Plant",
      idUnitProcess: "* Choose a process unit.",
      data: "* Choose a date.",
      operacao: "* Choose an operation",
      area: "* Choose a production line.",
      pn: "* Choose a Part Number",
      arvore: "* Choose a Sector",
      anomaly: "* The anomaly must be reported",
      imageKO: "KO_image",
      imageOK: "OK_image",
      causeRoot: "* The root cause must be reported",
      what: '* The question "What" must be reported',
      why: '* The question "Why" must be reported',
      when: '* The question "When" must be reported',
      where: '* The question "Where" must be reported',
      who: '* The question "Who" must be reported',
      how: '* The question "How" must be reported',
      how_many: '* The question "How Many" must be reported',
      imageProblem: "problem image",
      imageSolution: "Solution image",
      descriptionProblem: "* The problem description must be reported",
      descriptionSolution: "* The problem solution must be reported",
      imageDrawing: "design image",
      fabric: "* The manufacturer must be reported",
      effect: "efect_",
      updateDoc: "updating documents",
      coust: "* The cost must be reported",
      benef: "* The benefit must be reported",
    },
  },
  ponto_eletronico: {
    title: "Appointment registration",
    closeDrawer: "Close",
    btnEnviar: "Send",
    labelField: "Period",
    mobileFieldDay: "Day",
    mobileFieldData: "Data",
    mobileFieldInput: "Input",
    mobileFieldOut: "Output",
    mobileFieldView: "View",
    goBackEquip: "Return to Team",
    titleUpdateCache: "Click here to update the electronic point cache",
    resultPartial: "Parcial results",
    headerOne: "DAY",
    headerTwo: "DATA_REGISTER",
    headerThree: "1E",
    headerFour: "1S",
    headerFive: "2E",
    headerSix: "2S",
    headerSeven: "EXTRA",
    headerEight: "ADDITIONAL",
    headerNine: "FAULT",
    headerTen: "ALLOWANCE",
    headerEleven: "SITUATION",
  },
  contra_cheque: {
    titulo: "Paycheck",
    campoAnos: "Years",
    campoMeses: "Months",
    contraChequeTitulo: "Personal Information",
    contraChequeMatricula: "Registration",
    contraChequeNome: "Name",
    contraChequeFuncao: "Function",
    contraChequeAdmissao: "Admission Date",
    contraChequeVencimentos: "Payments",
    contraChequeDescontos: "Discounts",
    contraChequeLiquido: "Net",
    contraChequeBaseDeCalculo: "Calculation Base",
    contraChequeCod: "Code",
    contraChequeDescricao: "Description",
    contraChequeRef: "Reference",
    contraChequeValor: "Value",
    btnBaixar: "Download",
    btnBaixarTitulo: "Generate PDF of this paycheck",
    cnpj: "CNPJ",
    endereco: "Address",
    reciboSalario: "Salary Receipt",
    codigo: "Code",
    descricao: "Description",
    referencia: "Reference",
    vencimentos: "Payments",
    descontos: "Discounts",
    oneMonth: "January",
    twoMonth: "February",
    threeMonth: "March",
    fourMonth: "April",
    fiveMonth: "May",
    sixMonth: "June",
    sevenMonth: "July",
    eightMonth: "August",
    nineMonth: "September",
    tenMonth: "October",
    elevenMonth: "November",
    twelveMonth: "December",
    thirteenMonth: "13th",
    updateCache: "Update cache of the open sheet",
  },
  galeria_de_fotos: {
    titleBtnAdd: "Add a new album",
    labelBtnAddAlbum: "New Album",
    labelBtnAddPhoto: "Add Photos",
    titleEditAlbum: "Change the subject and title of the album",
    titleDelAlbum: "Delete album, includes photos, likes and comments",
    labelEditAlbum: "Edit Album",
    labelDelAlbum: "Delete Album",
    titleViewOptions: "Click to view options",
    titleViewPhotosAlbum: "Click here to view the photos",
    postedOn: "Posted on",
    titleTotalOf: "Total of",
    titleInAlbum: "photos in the album",
    titleAlbumVisibleTo: "Album visible to",
    titleFormAddAlbum: "Form to register an album",
    descriptionFormAddAlbum:
      "Fill in the fields for the intention to create a photo album describing a title, subject and visibility of the album",
    labelFormTitleAlbum: "* Title",
    placeholderTitleAlbum: "Describe the title here",
    errorTitleAlbum: "* Minimum of 3 characters",
    labelFormSubjectAlbum: "* Subject",
    placeholderSubjectAlbum: "Describe the subject here",
    errorSubjectAlbum: "* Minimum of 3 characters",
    labelPlantAlbum: "* Album visible to",
    errorPlantAlbum: "Choose at least one plant",
    labelBtnSendAlbum: "SAVE",
    titleFormDelAlbum: "Are you sure you want to delete?",
    subjectFormDelAlbum:
      "If you choose to delete, you will lose photos, likes and comments on this album.",
    labelBtnNo: "No",
    labelBtnYes: "Yes",
    listOfPhotos: "Album photos",
    titleImageOfAlbum: "Album image",
    titleTotalOfLikes: "Total likes",
    titleClickViewLegend: "Click to edit the photo caption",
    titleClickDelPhoto: "Click to edit the photo caption",
    titleTotalOfComments: "Total comments",
    altPhoto: "Album image",
    titlePhotoOfAlbum: "Album photo",
    goBack: "Go back",
    downloadPhoto: "Download",
    closePhoto: "CLOSE",
    nextPhoto: "NEXT",
    thisFile: "This file",
    notAccept: "is not an acceptable type",
    labelFormFilesPhoto:
      "Choose the photos that will be inserted to be part of this album.",
    labelFormFile: "Photos",
    labelBtnSendPhoto: "SEND",
    listOfComments: "List of comments",
    confirmationDelComment: "Do you really want to delete this comment?",
    placeholderAddComment: "Write your comment",
    listOfLikes: "List of Likes",
    titleClickChooseLike: "Click to choose the like",
    youLike: "You liked",
    closeLike: "Close",
    like: "Like",
    titleFormUpdLegend: "Form to edit the caption",
    writeNewLegendPhoto: "Write the new caption for the photo",
    labelFormLegend: "* Caption",
    placeholderFormLegend: "Describe the caption here",
    errorLegend: "Minimum of 3 characters",
    labelBtnSendLegend: "Minimum of 3 characters",
    titleFormDelPhoto: "Are you sure you want to delete?",
    subtitleFormDelPhoto:
      "If you choose to delete, you will lose all likes and comments on this photo",
  },
  feed_rh: {
    titleBirthDayCompany: "Company Birthdays",
    errStatusNotOK: "Error trying to get emotional status",
    errUnknown: "UNKNOWN ERROR",
    titleEmotionalClimate: "Business climate",
    descriptionEmotionalClimate: "How are you feeling?",
    today: "Today",
    btnNewPost: "NEW POST",
    feedNotFound: "No feed created so far.",
    btnEdit: "Edit",
    btnDelete: "Delete",
    titlePostedOn: "Posted on:",
    viewLikes: "View likes",
    notComments: "no comments",
    notLikes: "no likes",
    viewMinus: "View less",
    viewMore: "View more",
    postedOn: "Posted on ",
    titleWhenComments: "How many comments",
    altImageFeed: "Logo image in the feed",
    comment: "comment",
    comments: "comments",
    titleLikedYou: "You liked this",
    titleLike: "Like the post",
    like: "like",
    likes: "likes",
    animationLike: "Like",
    animationHappy: "Happy",
    animationZen: "Excited",
    animationLove: "Loving",
    animationConfused: "Confused",
    animationSad: "Sad",
    animationUngry: "Angry",
    titleFeedLikes: "Amount of likes by type",
    formDelTitleFeedDel: "Are you sure about this?",
    formDelSubtitleFeedDel:
      "If you choose to confirm the deletion of the feed, you will lose all comments and likes on this feed.",
    formAddUpdLabelTitle: "Title",
    formAddUpdLabelSubject: "Subject",
    formAddUpdLabelObservation: "Observation",
    formAddUpdLabelFile: "File",
    formAddUpdLabelPlant: "Feed visible for",
    formAddUpdPlaceholderTitle: "Describe the title here",
    formAddUpdPlaceholderSubject: "Describe the subject here",
    formAddUpdPlaceholderObservation: "Describe the observation here",
    formAddUpdPlaceholderFile: "File",
    formAddUpdPlaceholderPlant: "Feed visible for",
    formAddUpdErrorTitle: "* The title field must have at least 2 characters",
    formAddUpdErrorSubject:
      "* The subject field must have at least 2 characters",
    formAddUpdErrorFile: "* The file was not sent",
    formAddUpdErrorPlant: "* Choose at least one unit to be attached",
  },

  ouvidoria_view: {
    title: "Ombudsman",
    id: "ID",
    total: "Total of ombudsman:",
    subject: "Subject",
    situation: "Situation",
    dateCreated: "Date of creation",
    interaction: "Interaction: ",
    labelComponentPagination: "Click to toggle between pages",
    themePanel: "Themes",
    typePanel: "Types",
    titleBtnAdd: "Click to open a new ombudsman",
    titleBtnSearch: "Click to search for an ombudsman by Token",
    labelBtnAdd: "OMBUDSMAN",
    labelBtnSearch: "TOKEN",
    noData: "No ombudsman found",
    noDataInteraction: "Ombudsman not found",
    titleAdd: "Open ombudsman",
    description: "Description",
    plant: "Plant",
    attach: "Attachment",
    placeholderSubject: "Write the subject",
    placeholderText: "More details about the ombudsman",
    errorId: "* Choose at least one ID",
    errorType: "* Need to choose a type",
    errorTheme: "* Need to choose a theme",
    errorSubject: "* Minimum of 3 characters",
    errorDescription: "* Describe your request",
    errorSituation: "* Choose the new status",
    detailsOmbudsman: "Details",
    titleBtnGoBack: "Return to the ombudsman screen",
    labelBtnGoBack: "Return",
    titleInteraction: "Interact",
    labelBtnInteraction: "INTERACT",
    ombudsman: "Ombudsman: ",
    titleTab1: "History",
    titleTab2: "Details",
    placeholderSearchToken: "Token",
    titleSearchToken: "Search by Token",
    titleOmbudsmanCopyToken: "Anonymous ombudsman created",
    descriptionToken:
      "If you want to follow this ombudsman click on the token to copy it.",
    tokenCopySuccess: "Token successfully copied",
    labelBtnFilter: "Advanced Filter",
    titleBtnFilter: "Click to perform an advanced filter.",
    titleFilter: "Select the filter",
    titleFilterId: "Click to select the filter by ID",
    titleFilterTheme: "Click to select the filter by theme",
    titleFilterType: "Click to select the filter by type",
    titleFilterSituation: "Click to select the filter by status",
    statusOpen: "Open",
    statusClosed: "Finished",
    statusMonitoring: "Analyzing",
    ombudsmanStatus: "Ombudsman Status",
    ombudsmanNumber: "Ombudsman Number",
    createdAt: "Created At",
    lastInteraction: "Last Interaction",
    type: "Type",
    theme: "Theme",
    owner: "Owner",
    situacao: "Situation",
  },
  colaboradores: {
    pageTitle: "Manage Employees",
    fabTitle: "Add New Employee",
    fabLabel: "ADD",
    labelMat: "Registration",
    placeholderMat: "000000",
    labelThree: "TREE",
    placeholderThree: "Choose the tree",
    labelIsManager: "Is a manager?",
    errorMat: "* Required field",
    errorThree: "* Required field",
    formAddTitle: "Employee Registration",
    formAddDescription:
      "Enter the employee's registration, sector and whether they are a manager.",
    filterLabelManager: "Manager",
    filterLabelEligible: "Eligible",
    filterLabelActive: "Active",
    filterLabelInactive: "Inactive",
    labelTotal: "Total",
    optionsLabel: "Options",
    optionLabelMenu: "CHANGE MENU(S)",
    optionLabelManager: "MANAGER X STATUS X ELIGIBLE",
    optionLabelInfoPerson: "CHANGE PERSONAL INFORMATION",
    dateOfContract: "Admission",
    labelManagerOfThree: "Tree Manager",
    colabItemTitle: "Number of assigned menus",
    menuOfAccessTitle: "Access menu list",
    menuOfAccessDescription:
      "Listing of all available menus that can be (or are) assigned to the employee",
    placeholderFilterColab: "Filter for available menus",
    labelActiveInactiveAll: "Activate / Deactivate ALL",
    managerActiveEligibleTitle: "Manager X Active X Eligible",
    managerActiveEligibleDescription:
      "Activate or deactivate the employee to be a manager, activate or deactivate to be eligible or if they are authorized to log into the system.",
    labelEnableDisable: "Enable / Disable",
    labelPasswdTitle: "Password",
    labelPasswdDescription: "Enter a new password for the employee",
    labelPasswdPlaceholder: "Enter the password",
    errorPasswd: "* The password must have at least 6 characters",
    labelBtnSavePasswd: "Save New Password",
    labelThreeTitle: "Tree",
    labelThreeDescription: "Change the employee's tree if desired",
    labelThreePlaceholder: "Choose the tree",
    labelBtnSaveThree: "Update Tree",
    labelEmailTitle: "E-mail",
    labelEmailDescription: "Change or assign a new email to the employee",
    labelEmailPlaceholder: "Ex: name@email.com",
    errorEmail: "* Ex: name@email.com",
    labelBtnSaveEmail: "Change Email",
    labelName: "Name",
    placeholderName: "Employee's name",
    errorName: "* Required field",
    labelPlant: "Plant",
    placeholderPlant: "Choose the plant",
    errorPlant: "* Required field",
    labelFunction: "Role",
    placeholderFunction: "Choose the employee's role",
    errorFunction: "* Required field",
    labelDateAdmission: "Admission date",
    placeholderDateAdmission: "Choose the admission date",
    errorDateAdmission: "* Required field",
    placeholderPassword: "Enter the password",
    labelPassword: "Password",
    errorPassword: "* The password must have at least 6 characters",
    addColabTab1: "AUTOMATIC INCLUSION",
    addColabTab2: "MANUAL INCLUSION",
    formAddTitleManual: "Manual inclusion of employee",
    formAddDescriptionManual: "Enter the employee's data for manual inclusion.",
  },
  minha_equipe: {
    noDataTitle1: "Choose one of the options",
    noDataTitle2: "on the side",
    noDataTitle3: "of the panel to display data",
    placeholderSearchEmployee: "Enter the name or position",
    optionsPanelPointTitle: "Electronic Point",
    optionsPanelPointDescription: "See the team's electronic point",
    optionsPanelBankOfHoursTitle: "Bank of Hours",
    optionsPanelBankOfHoursDescription: "See the team's bank of hours",
    optionsPanelBankOfHoursGeneralTitle: "General Bank of Hours",
    optionsPanelBankOfHoursGeneralDescription: "See the general bank of hours",
    optionsPanelPerformanceEvaluationTitle: "Performance Evaluation",
    optionsPanelPerformanceEvaluationDescription: "",
    myEquipBankOfHoursGeneralPageTitle: "General Bank of Hours",
    myEquipBankOfHoursGeneralCredit: "Credit",
    myEquipBankOfHoursGeneralDebit: "Debit",
    myEquipBankOfHoursGeneralBalance: "Balance",
    myEquipBankOfHoursPageTitle: "Team's Bank of Hours",
    myEquipBankOfHoursFinalBalance: "FINAL BALANCE",
    myEquipPointPageTitle: "Team's Electronic Point",
    myEquipPointLabelPeriod: "Point Period",
    myEquipPointTotalResultPartial: "Partial Results",
    myEquipQuestionTitle: "Behavioral Skills Questionnaire",
    myEquipQuestionView:
      "Click on a collaborator to view the performance evaluation.",
    myEquipQuestionErrorName: "* Filling is required",
    myEquipQuestionObsEvaluatorLabel: "Evaluator's Observation",
    myEquipQuestionObsEvaluatorPlaceholder:
      "Enter the evaluator's observations",
    myEquipQuestionObsEvaluatedLabel: "Evaluated's Observation",
    myEquipQuestionObsEvaluatedPlaceholder:
      "Enter the evaluated's observations",
    myEquipQuestionEvaluatorError: "* Enter details about the evaluator",
    myEquipQuestionEvaluatedError: "* Enter details about the evaluated",
    myEquipQuestionErrorUnknown: "UNKNOWN ERROR",
    myEquipQuestionTitleObsQuestion: "Observations on the questionnaire",
    myEquipQuestionEvaluationResponseTitle: "Answered Evaluations",
    myEquipQuestionDownloadPdfTitle:
      "Click the button to download the evaluation in PDF",
    myEquipQuestionDownloadPdfBtnLabel: "Download PDF",
    myEquipQuestionHeaderResponseName: "Name",
    myEquipQuestionHeaderResponseMat: "Registration",
    myEquipQuestionHeaderResponseFunction: "Function",
    myEquipQuestionHeaderResponseEvaluator: "Evaluator",
    myEquipQuestionHeaderResponseDateOfEvaluation: "Date of Evaluation",
    myEquipQuestionHeaderResponsePageTitle: "Performance Evaluation",
    myEquipQuestionViewEvaluationResponsePageTitle: "Observations",
    myEquipQuestionEvaluationResponseAskBody: "Answer",
    myEquipQuestionEvaluationResponseObsEvaluatorTitle: "Evaluator's Answer",
    myEquipQuestionEvaluationResponseObsEvaluatedTitle: "Evaluated's Answer",
  },
  cadastro_projetos: {
    titlePage: "Project Registration",
    title: "Title",
    includeProject: "Include Project",
    titleAdvancedFilter: "Advanced search filters",
    advancedFilter: "Advanced filter",
    titleILeader: "List projects where I am the leader",
    ILeader: "I am the leader",
    titleIMember: "List projects where I am a member",
    IMember: "I am a member",
    titleMyDeport: "List projects from my department",
    myDeport: "My department",
    valueNotFound: "Not found",
    labelSituation: "Situation",
    labelCreatorProject: "Project Creator",
    labelLeader: "Leader",
    labelCode: "Code",
    labelOpeningDate: "Opening date",
    labelExpectedDate: "Expected date",
    labelDriver: "Driver",
    labelDescription: "Description",
    labelExpansion: "Expansion",
    labelArea: "Area",
    labelOperation: "Operation",
    labelLoss: "Loss",
    labelLossType: "Type of loss",
    labelProjectType: "Project type",
    labelPillar: "Pillar",
    labelIDMatrixDQA: "ID Matrix D / QA",
    labelSaving: "Saving",
    closeButton: "CLOSE",
    modelsTitle: "Models",
    membersTitle: "Members",
    attachmentsTitle: "Attachments",
    attach: "Attachment",
    wait: "Wait ...",
    dateHistory: "History date",
    mat: "Registration",
    fromSituation: "From situation",
    toSituation: "To situation",
    observation: "Observation",
    titleJustify: "Justifications defined in the project",
    subtitle: "Below are the justifications that were defined in the project",
    descriptionLabel: "Description",
    delayFactorLabel: "Delay factor",
    actionDescriptionLabel: "Action description",
    executedActionLabel: "Executed action",
    newExpectedDateLabel: "New expected date",
    justificationDateLabel: "Justification date",
    plant: "Plant",
    leaderProject: "Project leader",
    descriptionChecklist: "Checklist description",
    matEvaluator: "Evaluator registration",
    situation: "Situation",
    descriptionProject: "Project description",
    dateAvailable: "Evaluation date",
    overvallGrade: "Overall grade",
    responseChecklist: "Checklist responses",
    titleChecklist: "Title:",
    description: "Description:",
    response: "Response:",
    comment: "Comment:",
    approved: "Approved",
    reproved: "Disapproved",
    pending: "Pending",
    open: "Open",
    fi: "FI validation",
    cd: "CD validation",
    canceled: "Canceled",
    justify: "Justify",
    seeJustify: "See justification",
    seeChecklist: "See checklists",
    seeValuesCD: "CD values",
    changeMembers: "Change member(s)",
    returnCD: "Return CD Validation",
    closed: "Closed",
    clearFilter: "Clear filter",
    titleCard: "Click to clear filter",
    days: "day(s)",
    leader: "Leader",
    late: "Late for",
    members: "Members",
    code: "Code",
    area: "Area",
    openListProject: "Opening",
    previst: "Expected",
    newDatePrevist: "New Expected Date",
    labelOpen: "Opening",
    labelNewDatePrevist: "New Expected Date",
    viewHist: "View history",
    btnOptions: "Options",
    alterStatus: "Status change",
    descriptionUpdStatus:
      "Describe (optional) why you are changing to the status",
    observationPlaceholder: "An observation to make",
    attachFI: "FI Attachment (mandatory)",
    attachCD: "CD Attachment",
    attachLabel: "Attachment",
    labelDateClosedLabel: "Closing Date",
    placeholderDateClosed: "Set closing date",
    btnSend: "SEND",
    errorAttachFI: "To submit to FI Validation, the attachment is mandatory",
    errorDateClosed: "The new closing date needs to be informed",
    errorNoLimit: "No limit",
    formDateSaving: "Saving data form",
    valBenefic: "Benefit value",
    valCust: "Cost value",
    valBC: "BC value",
    valSaving: "Saving value",
    typeSaving: "Type of Saving",
    typeBC: "Type of BC",
    fieldRequired: "Field required",
    register: "REGISTER",
    chooseChecklist: "Choose the checklist",
    chooseChecklistPlaceholder: "Choose a checklist",
    responseChecklistInfo: "RESPOND",
    commentPlaceholder: "Comment your answer",
    optionError: "Choose an option",
    commentError: "Should not be blank",
    titleFilterAdvanced: "APPLIED FILTER",
    titleCancelMember: "Cancel the inclusion of the member",
    titleRemoveMember: "Remove the member from the project",
    titleAlterMember: "Change of project members",
    projectMembers: "Project members",
    newMember: "New Member",
    membersParticipants: "Participating members",
    save: "SAVE",
    addMember: "ADD MEMBER",
    collaborator: "Collaborator",
    cancel: "Cancel the inclusion of the member",
    chooseCollaborator: "Please choose the collaborator",
    defineParticipation: "Missing to define {0} % of participation",
    removeMember: "Remove the member from the project",
    changeProjectMembers: "Change of project members",
    participantMembers: "Participating members",
    error100Percent: "100% of participation reached. Unable to include members",
    errorBefore100Percent1: "Missing to define ",
    errorBefore100Percent2: " % of participation",
    pilar: "Pillar",
    sector: "Sector",
    lateFilterAdvanced: "Late",
    typeOfProject: "Type of project",
    applyFilter: "Apply Filter",
    errorProjectCode: "Need to choose at least 1 project code",
    errorPlant: "Need to choose at least 1 plant",
    errorPilar: "Need to choose at least 1 pillar",
    errorSector: "Need to choose at least 1 sector",
    errorLate: "Need to choose at least 1 sector",
    errorTypeOfProject: "Need to choose at least 1 type of project",
    descricao: "Description",
    expansao: "Is it an expansion project?",
    placeholderDescription: "Enter a description",
    errorDateOpen: "Format DD/MM/YYYY",
    errorDirection: "Choose a driver",
    errorDescription1: "Should not be blank (maximum ",
    errorDescription2: " characters)",
    errorExpansion: "Choose an option",
    errorArea: "Choose an area",
    errorModel: "Choose at least one model",
    errorOperation: "Choose an operation",
    errorLose: "Choose a loss",
    errorTypeLose: "Choose an option",
    errorTypeProject: "Choose an option",
    errorMatriz: "Should not be blank",
    errorSaving: "Enter in the format 0.00",
    errorLider: "Choose an option",
    errorMemberNull: "🤔 Fill in the member",
    errorPercentNot100: "The total percentage is less than 100%",
    errorPercentMore100: "The total percentage is more than 100%",
    errorNotLeader: "🤔 Fill in the project leader.",
    errorMemberLast: "🤔 Fill in the last included member.",
    errorTotal100: "100% participation reached",
    btnClose: "CLOSE",
    btnSave: "Save",
    titleLaunchProject: "Project launch",
    labelDateOpen: "Opening date",
    labelDirection: "Driver",
    labelExpansionProject: "Is it an expansion project?",
    labelModel: "Models",
    labelLose: "Loss",
    labelTypeLose: "Type of loss",
    labelTypeOfProject: "Type of project",
    labelPilar: "Pillar",
    labelMatriz: "ID Matrix D / QA",
    labelSavingAdd: "Expected saving",
    labelLeaderAdd: "Project leader",
    labelMember: "Member",
    labelPercent: "Member participation",
    labelAnexo: "Attachment(s)",
    labelMemberNumber: "Member",
    labelPercentMember: "Member participation",
    labelSavingInfo:
      "Attention, enter an expected saving value, if you don't know, type the number 0.00",
    btnNewMember: "New Member",
    optYes: "Yes",
    optNo: "No",
    optCronic: "Chronic",
    optExpordic: "Sporadic",
    titleRemoveMemberAdd: "Remove member",
    titleFormAdd: "Justification form",
    descriptionAddJustify: "Describe the project delay details",
    descricao_da_acao: "Solution description",
    fator_atraso: "Delay factor",
    acao: "Action",
    registrar: "Register",
    placeholderDescriptionAddJustify: "Describe the reason for the delay here",
    placeholderDescriptionAction:
      "Describe the action for non-recurrence of this delay factor",
    errorDescription: "Minimum of 3 characters",
    errorDescriptionAction: "Minimum of 3 characters",
    errorFactor: "Choose at least one factor",
    errorAction: "Choose an action",
    pointTitle: "Score reached",
    considerAcceptCd:
      "Do you consider it acceptable to advance the project to CD VALIDATION?",
    btnResponseAfter: "RESPOND LATER",
    btnReproved: "DISAPPROVED",
    btnApproved: "APPROVED",
  },
  cartao_seguranca: {
    errorOpenDate: "* Must be filled",
    errorTypeCard: "* One must be chosen",
    errorCategory: "* Inform the category",
    errorDescriptionCard:
      "* It should not be blank and should not exceed 230 characters",
    errorMat: "* Choose a registration",
    errorOperation: "* Inform the operation or N/A",
    errorTurn: "* Inform the shift",
    errorArea: "* Inform an area",
    errorModel: "* Inform the card model",
    labelTypeCard: "Choose the card type",
    nameLeader: "LEADER NAME",
    mat: "REGISTRATION",
    labelArea: "AREA",
    labelModel: "MODEL",
    labelCategory: "CATEGORY",
    labelOperation: "OPERATION",
    labelTurn: "SHIFT",
    labelDateOpen: "OPENING DATE",
    labelDescription: "DESCRIPTION",
    placeholderDescription: "Describe the problem...",
    labelAttachment: "ATTACHMENT(S)",
    textBtnSave: "SAVE",
    titleFormJustify: "Justification / Observation",
    labelJustify: "* Justification",
    placeholderJustify: "Type your justification...",
    errorMinChar: "Minimum of 3 characters",
    textBtnReprove: "REJECT",
    textBtnApprove: "APPROVE",
    titleMoveToAnalitycs: "Move to analysis",
    subtitleMoveToAnalitycs:
      "Fill in the form fields to determine the root cause and the planned action.",
    labelRootCause: "Root Cause",
    placeholderRootCause: "Type the root cause of the problem...",
    labelAction: "Planned action",
    placeholderAction: "Type the planned action to solve the problem",
    labelDatePrev: "Expected date",
    labelResponsible: "Responsible",
    titleJustifyAndObservation: "Justification / Observation",
    subtitleJustifyAndObservation:
      "Decide whether the card should move to the Open status or be Cancelled.",
    textBtnCancel: "CANCEL",
    textBtnValidate: "VALIDATE",
    attachment: "Attachment",
    titleCompleteEnvironment: "ENVIRONMENT VALIDATION",
    titleCompleteHealth: "HEALTH AREA VALIDATION",
    titleCompleteSafety: "SAFETY VALIDATION",
    titleCompleteValidation: "VALIDATION OF",
    card: "CARD",
    date: "DATE",
    identify: "IDENTIFICATION",
    name: "NAME",
    leaderName: "LEADER NAME",
    areaAndTurn: "AREA/SHIFT",
    descriptionProblem: "PROBLEM DESCRIPTION",
    local: "LOCATION",
    model: "MODEL",
    activity: "ACTIVITY",
    operation: "OPERATION",
    description: "DESCRIPTION",
    justifyAndObservation: "JUSTIFICATION / OBSERVATION",
    planType: "ACTION PLAN (SOLUTION)",
    rootCause: "ROOT CAUSE",
    actions: "PLANNED ACTIONS",
    dateInclude: "INCLUSION DATE",
    datePreview: "EXPECTED DATE",
    analyticsAndEfficiency: "EFFICACY ANALYSIS",
    statusInclude: "Included (To validate)",
    statusCancel: "Cancelled",
    statusOpen: "Open",
    statusAnalisys: "Under analysis",
    statusClosed: "Closed",
    statusOpenTitle: "Card with open status",
    statusIncludeTitle: "Card with included status to validate",
    statusCancelTitle: "Card with cancelled status",
    statusAnalisysTitle: "Card under analysis",
    statusClosedTitle: "Card with closed status",
    searchTitleLeader: "Search by leader",
    searchTitleCode: "Search by code",
    searchTitleTurn: "Search by shift",
    searchTitleArea: "Search by area",
    titlePage: "Safety Card",
    textCardTypeSecurity: "Security",
    textCardTypeHealth: "Health",
    textCardTypeEnvironmental: "Environmental",
    titleFabAdd: "Add new card",
    titleButtonCleanFilter: "Clear applied status filter",
    textButtonCleanFilter: "Clear filter",
    textOptionViewCard: "View card",
    textOptionValidateReject: "Validate / Reject",
    textOptionSendAnalysis: "Send for analysis",
    textOptionJustify: "Justify",
    textOptions: "Options",
    textType: "Type",
    textLeader: "Leader",
    textDescription: "Description",
    textOpenDate: "Opening date",
    textFabAdd: "ADD",
    view: "View",
    front: "Front",
    back: "Back",
  },
  helpdesk: {
    titleMoreOptions: "Click to see more options.",
    titleInfoRequester: "All tickets opened by this requester",
    placeholderGetRequestTicket: "Type the name",
    labelFilterAgent: "I'm an Agent",
    titleFilterAgent: "Displays only tickets where I am the agent",
    labelFilterRequester: "Requesters",
    titleFilterRequester:
      "Determines from which requester you want to see the tickets",
    labelMaintenanceSubjectSuperAgent: "SUBJECT MAINTENANCE",
    labelMaintenanceStatusSuperAgent: "STATUS MAINTENANCE",
    labelMaintenanceCriticalitySuperAgent: "CRITICALITY MAINTENANCE",
    labelBtnNewTicket: "NEW TICKET",
    titleSubjects: "Subjects",
    titleStatus: "Status",
    titleAnimationNoData: "There are no tickets in the informed filter",
    titleComponentPagination: "Click to switch between pages",
    titleFieldId: "ID",
    titleFieldPlant: "Plant",
    titleFieldRequester: "Requester",
    titleFieldCriticality: "Criticality",
    titleFieldAgent: "Agent",
    titleFieldStatus: "Status",
    titleFieldTitle: "Title",
    titleFieldLastInteraction: "Interaction / Deadline",
    titleBtnOrderTicket: "Click to order.",
    titleTotalTickets: "Total number of tickets created",
    titleBtnNewTicket: "Click to open a new ticket",
    titleTicket: "Click to see ticket details",
    titleTicketNumber: "Ticket No.",
    titleTicketNotAssigned: "Not assigned",
    titleSubject: "Subject",
    titleInteraction: "Interaction",
    titlePlant: "Plant",
    titleAgent: "Agent",
    titleIndicatorLate: "This ticket is late (the deadline has expired)",
    labelIndicatorLate: "Late",
    titleBtnDelete: "Click to delete the status",
    titleBtnMove: "Click to define to which status this can lead",
    title: "Status maintenance",
    subtitle:
      "Create, change, delete and activate/deactivate statuses used in the helpdesk.",
    headerName: "Status",
    headerAuthorized: "Authorized to Interact",
    headerColor: "Color",
    headerActive: "Situation",
    headerDelete: "Delete",
    headerMove: "From / To",
    labelActive: "Active",
    labelInactive: "Inactive",
    labelAgent: "Agent",
    labelRequester: "Requester",
    titleBtnAdd: "Include",
    titleFromTo: "Status Destination Definition",
    subtitleFromTo:
      "Choose the destinations where the status on the left can go when it needs to be moved.",
    canInteract: " can interact in this status.",
    labelBtn: "SAVE",
    fromStatus: "HELPDESK IN THIS STATUS",
    toStatus: "CAN GO TO THESE STATUS",
    titleDel: "Status deletion",
    subtitleDel: "Do you really want to delete the status?",
    titleAddStatus: "Add Status",
    labelName: "Status name",
    labelAuthorized: "Authorized to Interact",
    labelSituation: "Situation",
    labelColor: "Color",
    errorName: "* Name must have at least 3 characters",
    errorAuthorized: "* Define who can interact in this status",
    erroSituation: "* Choose between activating or deactivating the subject",
    errorColor: "* Choose a color for the status",
    placeholderName: "Status name",
    placeholderAuthorized: "Authorized to Interact",
    placeholderSituation: "Situation",
    placeholderColor: "Color",
    labelActivated: "Activated",
    labelDeactivated: "Deactivated",
    titleBtnGoBack: "Return to the ticket screen",
    labelBtnGoBack: "RETURN",
    labelFilterAdvancedTicket: "Ticket",
    labelFilterAdvancedSubject: "Subject",
    labelFilterAdvancedAgent: "Agent",
    labelFilterAdvancedStatus: "Status",
    labelFilterAdvancedDelayed: "Delayed",
    labelFilterAdvancedApplicant: "Requester",
    labelFilterAdvancedSeverity: "Criticality",
    labelFilterAdvancedExclude: "Do you really want to exclude the filter?",
    titleFilterAdvancedMyFilters: "My saved filters",
    labelNoAccessToLocalStorage: "No access to local recording feature",
    titleFilterAdvancedChooseFilter: "Choose the filter to be applied",
    titleFilterAdvancedNoFilterSelected: "No filter selected",
    labelFilterAdvancedSaveFilter: "Save Filter",
    labelFilterAdvancedQuestionSaveFilter: "Define a name for the filter",
    errorFilterAdvancedTicket: "* Please choose at least 1",
    errorFilterAdvancedSubject: "* Please choose at least 1",
    errorFilterAdvancedAgent: "* Please choose at least 1 agent",
    errorFilterAdvancedStatus: "* Please choose at least 1 status",
    errorFilterAdvancedApplicant: "* Please choose at least 1 requester",
    errorFilterAdvancedSeverity: "* Please choose at least 1 criticality",
    titleFilterAdvancedDeleteFilter: "Do you really want to delete the filter?",
    titleDetails: "Details",
    titleInteractions: "Interactions",
    labelStatus: "Status",
    labelTicketNumber: "Ticket Number",
    labelCreated: "Created on",
    labelLastInteraction: "Last interaction",
    labelDeadline: "Deadline",
    labelSubject: "Subject",
    titleCriticality: "Criticality level",
    titleAlterCriticality: "Change criticality",
    labelNoAgent: "Not assigned",
    titleRequestersInCopy: "Collaborators in copy",
    labelBtnEdit: "Edit",
    labelAgentResponsible: "Responsible Agent",
    titleClickHereToStartAnInteraction: "Click here to start an interaction",
    titleClickToIncrement:
      "Click to increment more details without updating the status",
    labelClose: "CLOSE",
    labelIncrement: "INCREMENT",
    labelAttachment: "ATTACHMENT",
    placeholderDescription: "Enter the description",
    errorDescription: "Description of the occurrence is required",
    placeholderStatusTo: "Change the ticket status...",
    errorStatusTo: "Choose the status that will receive the request",
    labelSendEmail: "Send notification email",
    titleAlterAgent: "Change responsible agent",
    titleMainCritical: "Criticality Maintenance",
    subtitleMainCritical:
      "Create, change, delete and activate/deactivate criticalities used in the helpdesk.",
    headerNameCritical: "Criticality",
    headerIcon: "Icon",
    labelDeactive: "Inactive",
    titleBtnAddActive: "Active",
    titleAddCritical: "Add Criticality",
    titleAddCriticalUpd: "Update criticality",
    labelNameAddCritical: "Criticality name",
    labelIcon: "Icon",
    errorNameAddCritical: "* Name must have at least 3 characters",
    errorIcon: "* The icon must have at least 1 character",
    errorSituationAddCritical:
      "* Choose between activating or deactivating the criticality",
    placeholderNameAddCritical: "Criticality name",
    placeholderIcon: "Icon",
    placeholderSituationAddCritical: "Situation",
    labelDeactiveAddCritical: "Deactivated",
    titleSubjectMain: "Subject Maintenance",
    subtitleSubject:
      "Create, change, delete and activate/deactivate subjects used in the helpdesk.",
    headerNameSubject: "Subject",
    headerPraz: "Deadline (days)",
    titleSubjectBtnAdd: "Add",
    titleSubjectDel: "Subject deletion",
    subtitleSubjectDel: "Do you really want to delete the subject?",
    titleSubjectAdd: "Add Subject",
    titleSubjectAddUpd: "Update subject",
    labelNameSubjectAdd: "Subject name",
    labelPraz: "Deadline (in days)",
    errorNameSubjectAdd: "* Name must have at least 3 characters",
    errorPraz: "* Deadline must be at least 1 day",
    errorSituationSubjectAdd:
      "* Choose between activating or deactivating the subject",
    placeholderNameSubjectAdd: "Subject name",
    placeholderPraz: "Deadline (in days)",
    placeholderSituationSubjectAdd: "Situation",
    titleAlterEnvolved: "Change list of collaborators in copy",
    labelInvolved: "Collaborators in Copy",
    errorInvolved: "* Choose at least 1 collaborator",
    titleAlterCritical: "Change Ticket Criticality",
    labelCriticality: "Ticket Criticality",
    errorCriticality: "* Choose a valid criticality",
    labelTitleAdd: "Title",
    labelFiles: "Files",
    labelColabCopy: "Collaborators in Copy",
    placeholderTitle: "Describe a title to the problem",
    errorTitle: "* Minimum of 3 characters",
    errorSubject: "* Choose a subject",
    errorMat: "* Choose the requester's registration",
    titleNewTicket: "Ticket Opening",
    advancedFilter: "Advanced filter",
    labelBtnInteraction: "Interaction",
  },
  rnci_ncp: {
    titleCancelStatus: "Do you really want to cancel this status?",
    subtitleCancelStatus: "The RNCI will be deleted and cannot be recovered",
    subtitleCancelStatus2: "The RNCI will return to the OPEN status",
    subtitleCancelStatus3: "The RNCI will return to the LINE UP status",
    tituloBuscaAvancada: "Advanced search",
    descricaoBuscaAvancada:
      "Choose from the options and set up an advanced search.",
    wait_generate_pdf: "Please wait, generating PDF...",
    rotuloBtnFiltro: "ADVANCED SEARCH",
    tituloSemDados: "No data was found",
    tituloSemDadosRetorno: "Trying to load necessary data...",
    titulo: "RCNI",
    tituloPaginacao: "Click to switch between pages",
    codigo: "Code",
    anomalia: "Anomaly: ",
    data: "Creation Date: ",
    area: "Area: ",
    rotuloBotaoAdd: "New RNCI",
    tituloBotaoAdd: "Create new RNCI",
    tituloForm: "INTERNAL QUALITY ALERT",
    formAuditor: "AUDITOR",
    formConjunto: "SET",
    formModelo: "MODEL",
    formTurno: "SHIFT",
    formQtdKO: "KO QUANTITY",
    formQtdSuspeita: "SUSPECT QUANTITY",
    formOperacao: "OPERATION",
    formRNCI: "RNCI N°",
    formPN: "PN",
    formRastreabilidade: "TRACEABILITY",
    formSubidaDeLinha: "LINE UP",
    formFalhaForaControle: "OUT OF CONTROL FAILURE",
    formTwttp: "TWTTP",
    formData: "DATE",
    formHora: "TIME",
    formErrorAuditor: "Please inform at least one collaborator",
    formErrorConjunto: "Choose at least one area",
    formErrorModelo: "Choose at least one model",
    formErrorTurno: "Choose at least one shift",
    formErrorQtdKO: "Minimum 0",
    formErrorQtdSuspeita: "Minimum 0",
    formErrorOperacao: "Operation",
    formErrorRNCI: "RNCI N°",
    formErrorPN: "Please inform the PN",
    formErrorRastreabilidade: "Please inform the traceability",
    formErrorSubidaDeLinha: "Did it line up?",
    formErrorFalhaForaControle: "Was it an out of control failure?",
    formErrorTwttp: "Mandatory",
    formErrorData: "Please inform the date",
    formErrorHora: "Please inform the time",
    formErrorAnomalia: "Please inform the anomaly",
    formAuditorPlaceholder: "Inform the auditor",
    formConjuntoPlaceholder: "Area",
    formModeloPlaceholder: "Model",
    formTurnoPlaceholder: "Shift",
    formQtdKOPlaceholder: "EX: 1",
    formQtdSuspeitaPlaceholder: "EX: 1",
    formOperacaoPlaceholder: "Operation",
    formRNCIPlaceholder: "RNCI N°",
    formPNPlaceholder: "PN",
    formRastreabilidadePlaceholder: "Traceability",
    formSubidaDeLinhaPlaceholder: "Line up",
    formFalhaForaControlePlaceholder: "Out of control failure",
    formTwttpPlaceholder: "Twttp",
    formDataPlaceholder: "Date",
    formHoraPlaceholder: "Hour",
    formAnomaliaPlaceholder: "Describe the anomaly...",
    formAnomalia: "ANOMALY",
    formSim: "YES",
    formNao: "NO",
    twttpGerou: "Caused the problem",
    twttpDeixou: "Let it pass",
    twttpNaoAplicavel: "Not applicable",
    tituloPergs: "5W+1H",
    tituloKO: "KO",
    tituloOK: "OK",
    tituloImagemIdent: "RACK OR BOX IDENTIFICATION",
    formOque: "WHAT?",
    formQuando: "WHEN?",
    formOnde: "WHERE?",
    formQuem: "WHO?",
    formQual: "WHICH?",
    formComo: "HOW?",
    formErrorOque: "Mandatory",
    formErrorQuando: "Mandatory",
    formErrorOnde: "Mandatory",
    formErrorQuem: "Mandatory",
    formErrorQual: "Mandatory",
    formErrorComo: "Mandatory",
    formOquePlaceholder: "What happened?",
    formQuandoPlaceholder: "When did it happen?",
    formOndePlaceholder: "Where did it happen?",
    formQuemPlaceholder: "Who identified it?",
    formQualPlaceholder: "What is the problem level?",
    formComoPlaceholder: "How did it occur?",
    formFenomenoRevisado: "REVIEWED PHENOMENON",
    formErrorFenomenoRevisado: "Mandatory",
    formFenomenoRevisadoPlaceholder: "Describe the phenomenon.",
    formCausaRaiz: "POSSIBLE ROOT CAUSE",
    formCausaRaizPlaceholder: "Describe here...",
    formErrorCausaRaiz: "Mandatory",
    tituloBotaoVoltar: "Click to return",
    rotuloBotaoFormSalvar: "SAVE",
    tituloBotaoRemoverImagem: "Click to remove image",
    formRotuloImagem: "Insert the image here",
    formErrorImagemOK: "Image required",
    formErrorImagemKO: "Image required",
    formErrorImagemIdent: "Image upload error",
    situacaoQualidade: "QUALITY",
    situacaoSubida: "LINE UP",
    situacaoAberto: "OPEN",
    opcaoDetalhes: "Details",
    opcaoSubida: "Line Up",
    opcaoDownloadPdf: "Download PDF",
    opcaoQualidade: "Move Quality",
    rotuloBotaoVerOpcoes: "OPTIONS",
    tituloBotaoVerOpcoes: "Displays the RNCI manipulation options",
    tituloFormSubida: "Line Up Form",
    formPlaceholderTempoGasto: "Inform the time spent",
    formTempoGasto: "Time spent",
    formPlaceholderMod: "Enter the direct labor",
    formMod: "Direct Labor",
    formPlaceholderMoi: "Enter the indirect labor",
    formMoi: "Indirect Labor",
    formPlaceholderOK: "Enter the OK quantity",
    formOK: "OK Quantity",
    formPlaceholderKO: "Enter the KO quantity",
    formKO: "KO Quantity",
    formPlaceholderObservacao: "Describe an observation",
    formObservacao: "Observation",
    formErrorTempoGasto: "* Please enter the time spent HH:MM",
    formErrorMod: "* Minimum value 0",
    formErrorMoi: "* Minimum value 0",
    formErrorOK: "* Minimum value 0",
    formErrorKO: "* Minimum value 0",
    formErrorObservacao: "* Describe an observation minimum 3 characters",
    tituloFormQualidade: "Quality Form",
    formPlaceholderCodigoProjeto: "Enter the project code",
    formCodigoProjeto: "Project Code",
    formPlaceholderProjeto: "Does this RNCI have any project link?",
    formProjeto: "Does this RNCI have any project link?",
    formPlaceholderAcoes: "Describe the actions",
    formAcoes: "Action",
    formErrorProjeto: "* Choose Yes or No",
    formErrorCodigoProjeto: "* Enter the project code",
    formErrorAcoes: "* Describe the action minimum 3 characters",
    abaForm1: "RNCI",
    abaSubida: "LINE UP",
    abaQualidade: "QUALITY",
    rotuloBotaoVoltar: "BACK",
    subidaLinhaMod: "Direct Labor",
    subidaLinhaMoi: "Indirect Labor",
    subidaLinhaTempo: "Working Time",
    subidaLinhaOK: "OK Pieces Quantity",
    subidaLinhaKO: "KO Pieces Quantity",
    subidaLinhaObs: "Labor Observation",
    qualidadeCodProjeto: "Project Number",
    qualidadeObs: "Actions",
    filtroNomeStatus: "STATUS",
    filtroNomeStatusErro: "* Choose at least one",
    filtroNomeMatricula: "REGISTRATION",
    filtroNomeMatriculaErro: "* Choose at least one",
    filtroNomeArea: "AREA",
    filtroNomeAreaErro: "* Choose at least one",
    filtroNomeTurno: "SHIFT",
    filtroNomeTurnoErro: "* Choose at least one",
    filtroNomeRnci: "RNCI CODE",
    filtroNomeRnciErro: "* Choose at least one",
    filtroNomeOperacao: "OPERATION",
    filtroNomeOperacaoErro: "* Choose at least one",
    filtroNomePN: "PN",
    filtroNomePNErro: "* Choose at least one",
    opcaoExcluirRnci: "Delete RNCI",
    opcaoRetornarAbertoRnci: "Return to open",
    opcaoRetornarSubidaDeLinhaRnci: "Return to line up",
  },
  manutencao_variaveis: {
    titlePage: "Variable Maintenance",
    tabOne: "Collaborators",
    tabTwo: "Variables",
    tabThree: "Global Variables",
    labelBtnAddVar: "Add",
    labelBtnEditVar: "Edit",
    labelBtnDelVar: "Delete",
    labelBtnAddVarGlobal: "Add",
    titleEditVarGlobal:
      "Edit the variable both in its description and its value",
    labelBtnEditVarGlobal: "Edit",
    titleDelVarGlobal: "Delete this global variable (Authorized only)",
    labelBtnDelVarGlobal: "Delete",
    labelValueActualy: "Current value:",
    labelVarName: "Variable Name",
    placeholderVarName: "Enter the variable name",
    labelVarDescription: "Description",
    placeholderVarDescription: "Enter the variable description",
    labelVarValue: "Value",
    placeholderVarValue: "Enter the initial value of the variable",
    errorVarDescription: "* Minimum of 3 characters",
    errorVarName: "* Minimum of 3 characters",
    errorVarValue: "* Required field",
    titleNewVar: "Registration of new global variable",
    descriptionNewVar:
      "Fill in the values below to register a new global variable in the system.",
    titleFormAddVarUser: "USER VARIABLE MAINTENANCE",
    labelVarUserName: "Variable",
    placeholderVarUserName: "Enter the variable name",
    errorVarUserName: "* Minimum of 3 characters",
    labelVarUserDescription: "Description",
    placeholderVarUserDescription:
      "A description of what it intends to address",
    errorVarUserDescription: "* Minimum 3 characters",
    labelBtnVarUserSend: "SEND",
    titleFormDelVarGlobal: "Do you really want to delete the variable?",
    descriptionFormDelVarGlobal1: "If you really want to delete the variable",
    descriptionFormDelVarGlobal2: " click YES.",
    tabVarGlobal1: "Edit value",
    tabVarGlobal2: "Edit description",
    labelEditVarGlobalValue: "Value",
    placeholderEditVarGlobalValue: "Enter the new value of the variable",
    errorEditVarGlobalValue: "* Required field",
    descriptionEditVarGlobal:
      "Enter below the value that should be assigned to the variable",
    labelEditVarGlobalDescription: "Description",
    placeholderEditVarGlobalDescription: "Enter the variable description",
    errorEditVarGlobalDescription: "* Minimum of 3 characters",
    descriptionEditVarGlobalDesc:
      "Enter below the description to be assigned to the variable",
    titleListVarColab: "User variable list",
  },
  absenteismo: {
    title: "Absenteeism",
    labelDateFrom: "Date from",
    labelDateTo: "Date to",
    errorDateFormat: "Invalid format",
    labelBtnSearch: "Search",
    labelViewAll: "View all",
  },
  twttp: {
    labelSituationViewDetails: "Situation",
    labelSectorViewDetails: "Sector",
    labelInterviewedViewDetails: "Interviewee",
    labelInterviewerViewDetails: "Interviewer",
    labelInterviewDateViewDetails: "Interview Date",
    labelPrevDateViewDetails: "Expected Date",
    labelActionDateViewDetails: "Deadline for Action",
    labelTypeViewDetails: "Type of TWTTP",
    labelProblemViewDetails: "Problem",
    labelProcessViewDetails: "Process / Machine",
    labelModelViewDetails: "Model(s)",
    labelErrorFeatureViewDetails: "Error Feature",
    labelErrorDetailViewDetails: "Error Detail",
    labelProblemOriginViewDetails: "Problem Origin",
    labelFactorViewDetails: "Factor",
    labelShiftViewDetails: "Shift",
    labelRejectionJustifyViewDetails: "Rejection Justification",
    labelTimeViewDetails: "Time Required for Analysis",
    labelAttachmentViewDetails: "Attachment",
    labelAttachmentValidationViewDetails: "Validation Attachment(s)",
    labelCodeFilterAdvanced: "Code",
    labelTypeFilterAdvanced: "Type",
    labelOriginFilterAdvanced: "Origin",
    labelInterviewerFilterAdvanced: "Interviewer",
    labelInterviewedFilterAdvanced: "Interviewee",
    labelSectorFilterAdvanced: "Sector",
    labelAreaFilterAdvanced: "Area",
    labelLateFilterAdvanced: "Late",
    labelColabInterviewToInterview: "Interviewee Collaborator",
    labelInterviewerToInterview: "Interviewer",
    errorValueRequiredToInterview: "* Required Value",
    labelJustifyToInterview: "Explanatory Note",
    placeholderJustifyToInterview: "Write an explanatory note...",
    errorJustifyRequiredToInterview: "* Minimum of 3 characters",
    labelActionToInterview: "Action to be taken",
    descriptionColabToInterview: "Collaborator to be interviewed",
    descriptionToInterview: "Interviewer",
    titleTwttpResponseQuestion: "TWTTP ID -",
    titleAvatarToInterview: "Interviewee",
    titleAvatarToInterviewer: "Interviewer (evaluator)",
    titleTwttpResponseDateOpen: "Interview Date",
    labelTwttpResponseQuestion: "Question",
    labelTwttpResponse: "Answer",
    labelTwttpResponseNote: "Explanatory Note",
    labelJustifyFormMove: "* Justification",
    placeholderJustifyFormMove: "Enter a justification...",
    errorJustifyRequiredFormMove: "* Minimum 3 characters",
    titleReprovedFormMove: "Reject",
    subtitleReprovedFormMove:
      "Please provide the reason why you want to reject this TWTTP.",
    titleConfirmFormMove1: "Do you really want to update to",
    subtitleConfirmFormMove:
      "This change cannot be undone. If you confirm, the status of the twttp will be changed to",
    errorAttachmentRequiredFormMoveValidation: "Attachment required",
    titleFormMoveValidation: "Move TWTTP to Validation",
    subtitleFormMoveValidation:
      "In order for this TWTTP to be moved to validation, an attachment must be included in the field below",
    errorUnknown: "Unknown error",
    labelOpenDateFormAdd: "Opening Date",
    labelTwttpTypeFormAdd: "Type of TWTTP",
    labelProblemFormAdd: "Problem",
    labelProcessMachineFormAdd: "Process / Machine",
    labelModelsFormAdd: "Models",
    labelSectorInterviewedFormAdd: "Sector to be interviewed",
    labelErrorFeatureFormAdd: "Error Feature",
    labelErrorDetailFormAdd: "Describe the error in detail",
    labelProblemOriginFormAdd: "Problem Origin",
    labelFactorFormAdd: "Factor",
    labelShiftFormAdd: "Shift",
    labelAnalysisTeamFormAdd: "Time required for analysis",
    labelAttachmentFormAdd: "Attachment",
    titleTwttpFormAdd: "TWTTP",
    placeholderDetailErrorFormAdd: "Describe the error detail",
    errorAttachmentRequiredFormAdd: "Attachment required",
    errorOpenDateRequiredFormAdd: "Please provide the date",
    errorTwttpTypeRequiredFormAdd: "Please provide the type of TWTTP",
    errorProblemRequiredFormAdd: "Please provide the problem",
    errorAreaRequiredFormAdd: "Please provide the area",
    errorModelRequiredFormAdd: "At least one model needs to be selected",
    errorSectorRequiredFormAdd: "Please provide the sector",
    errorDetailErrorRequiredFormAdd: "Please provide the error detail",
    errorProblemOriginRequiredFormAdd: "Please provide the problem origin",
    errorErrorFeatureRequiredFormAdd: "Please provide the error feature",
    errorAnalysisTeamRequiredFormAdd: "Please provide the analysis team(s)",
    errorFactorRequiredFormAdd: "Fill in the factor",
    errorShiftRequiredFormAdd: "Fill in the shift",
    titleTwttpConfirm: "Do you really want to cancel this TWTTP?",
    subtitleTwttpConfirm:
      "Once this change is made, it will no longer be possible to cancel this procedure",
    labelWaitToPdf: "Wait, generating PDF...",
    errorInternal: "INTERNAL SERVER ERROR",
    titleTwttp: "TWTTP - HERCA",
    labelCleanFilter: "Clear Filter",
    titleNoData: "No Data to display",
    labelToInterview: "Interview",
    labelFinishInterview: "Finish interview",
    labelToValidation: "Send for validation",
    labelValidation: "Approve",
    labelMoveToExecution: "Move to execution",
    labelCancel: "Cancel",
    labelCode: "Code",
    labelSector: "Sector",
    labelArea: "Area",
    labelProblem: "Problem",
    labelOptions: "Options",
    titleOpenDateTwttp: "Twttp opening date",
    titleOpenDate: "Opening date",
    titlePrazActionTwttp: "Deadline for Twttp action",
    titlePrazAction: "Deadline for action",
    labelFilterAdvanced: "Advanced Filter",
    titleAddNewTwttp: "Add new Twttp",
    labelBtnAddTwttp: "ADD",
    twttpStatusA: "Open",
    twttpStatusV: "Validation",
    twttpStatusP: "In Interview",
    twttpStatusS: "In Execution",
    twttpStatusE: "Closed",
    twttpStatusVR: "View Responses",
    twttpStatusC: "Cancelled",
    twttpStatusBPDF: "Download TWTTP PDF",
    twttpStatusR: "Rejected",
    labelViewAnswers: "View Responses",
    labelReprove: "Reject",
    twttpStatusY: "Production V.",
    labelToValidationHigher: "Send for production validation",
    titleFormMoveValidationProd:
      "Sending the TWTTP from Production Validation status to Validation",
    subtitleFormMoveValidationProd:
      "If you are sure you want to move the TWTTP to validation, click YES",
  },
  registro_emocional: {
    errorInternal: "Internal server error",
    errorUnknown: "Unknown error when trying to retrieve emotions",
    title: "Business climate record",
    labelQuantity: "Quantity",
    subtitleNotFound: "Nothing found",
    subtitleNoFeelings: "No feelings recorded",
    subtitleBiggestFeeling: "Biggest feeling in the period",
    titleCompanyFeels: "How the company feels overall",
    titleEmotionGraph: "Business climate per day",
    subtitleEmotionGraph: "Biggest feeling recorded each day",
    titleEmotionGraphLottie1: "We had more collaborators",
    titleEmotionGraphLottie2: "on this day",
    titleEmotionGraphToday: "List of emotions selected on this day",
    labelSector: "Sector",
    subtitleOtherReaction: "Other reactions presented in the period",
    titleOtherReactionLottie: "How the company feels overall",
    labelPerQuantity: "(by quantity)",
  },
  gestao_helpdesk: {
    labelFilter: "Filter by period",
    labelFilterAdvanced: "Advanced filter",
    tickets: "Tickets",
    titleChartStatus: "Tickets by status",
    titleChartSubject: "Tickets by subject",
    titleChartAgent: "Tickets by agent",
    titleChartSector: "Tickets by sector",
    titleChartUser: "Tickets by user",
    titleChartMonth: "Tickets by month",
    timeMedium: "Average service time (general)",
    titleChartTimeMedium: "Average service time by Subject",
    filterNameStatus: "Status",
    filterErrorStatus: "* Choose at least one Status",
    filterNameAgent: "Agent",
    filterErrorAgent: "* Choose at least one Agent",
    filterNameSubject: "Subject",
    filterErrorSubject: "* Choose at least one Subject",
    filterNameSector: "Sector",
    filterErrorSector: "* Choose at least one Sector",
    filterNameUser: "User",
    filterErrorUser: "* Choose at least one User",
    filterNamePlant: "Plant",
    filterErrorPlant: "* Choose at least one Plant",
    titleSearchAdvanced: "Advanced search",
    descriptionSearchAdvanced:
      "Choose among the options and set up an advanced search.",
  },
  ferias: {
    labelGeoAccept: "You have already provided your location!",
    labelGeo: "Allow Location",
    titleSignature: "Your signature",
    clearSignature: "Clear",
    saveSignature: "Save",
    titleImageLoad: "User image loaded",
    labelPhoto: "Take Photo",
    labelPhotoInsert: "Photo inserted",
    labelPhotoRemove: "Remove photo",
    alertRemoveImage: "Do you really want to remove the photo?",
    labelTerm: "Mark here if you agree with the values reported above.",
    labelSaveBtn: "CONFIRM RECEIPT",
    labelBtnDownloadWarningVacation: "Vacation Receipt",
    errorNotFoundIP: "Error when trying to retrieve the IP",
    errorNotExistsWarningVacation: "There is no vacation warning to download",
    title: "Vacation receipts",
    labelBtn: "Sign",
    initialVacation: "Start",
    finalVacation: "End",
    returnOfVacation: "Return from vacation",
    quantityOfDays: "Number of days",
    dateOfPayment: "Payment date",
    signature: "Signed",
    notSignature: "Not signed",
    labelBtnVacation: "Vacation Receipt",
    labelBtnSalaryBonus: "Bonus Receipt",
    titleSalaryBonus: "BONUS (TOTAL)",
    titleVacation: "VACATION (Total)",
    verb: "Item",
    provent: "EARNINGS",
    discount: "DISCOUNT",
  },
  estatisticas: {
    labelToday: "Today",
    labelThisMonth: "This month",
    labelLastSixMonths: "Last 6 months",
    labelByPeriod: "By period",
    labelAccessBySO: "Access by O.S",
    labelAccessByBrowser: "Access by browser",
    labelAccessByRoute: "Access by route",
    title: "Access statistics",
    labelQuantity: "Quantity",
    periodOf: "Period of",
    periodTo: "to",
    access: "access(es)",
    titleAccessByMenu: "Access by page",
    titleTopSevenUser: "Top 7 Users",
    titleAccessByDay: "Access by day",
    dateFrom: "Date from",
    dateTo: "Date to",
    titleFilterByPeriod: "Filter by period 🧐",
    subtitleFilterByPeriod: "🔎 Choose a date you want to see the data",
    format: "Format",
    btnSend: "SEND",
  },
  manutencao_ativos: {
    titlePage: "Asset Maintenance",
    subtitlePage:
      "Choose from the available options to perform maintenance on assets that are used on various pages.",
    labelArea: "Area",
    titleLabelArea: "Click here to perform Area maintenance",
    labelModelo: "Model",
    titleLabelModelo: "Click here to perform Model maintenance",
    labelPerda: "Loss",
    titleLabelPerda: "Click here to perform Loss maintenance",
    labelPilar: "Pillar",
    titleLabelPilar: "Click here to perform Pillar maintenance",
  },
  manutencao_area: {
    titleArea: "Area Maintenance",
    searchAreaPlaceholder: "Enter the name of the area",
    modelInactive: "Inactive Model (cannot be used)",
    modelActive: "Active Model",
    active: "ACTIVE",
    inactive: "INACTIVE",
    titleBtnIconCancel: "Cancel area editing",
    titleBtnIconEdit: "Click to edit the area",
    titleSituationArea: "Area situation",
    titleSituationAreaActive: "Active for use",
    titleSituationAreaInactive: "Deactivated for use",
    titlePlant: "Plant",
    listOfModels: "List of models",
    labelModelName: "Model",
    titleModelThis: "This model is",
    titleModelActive: " activated ",
    titleModelInactive: " deactivated ",
    titleModelThis2: "for this area",
    placeholderAreaName: "Enter the name of the area",
    errorAreaName: "* Required field",
    labelArea: "Area",
    titleName: "Name",
    errorAreaMin: "* Minimum of 3 characters",
    titleAddArea: "Inclusion of a new area",
    subtitleAddArea: "Enter the name of the new area to be included",
  },
  manutencao_modelo: {
    situationA: "Situation: ACTIVE",
    situationDefault: "Situation: INACTIVE",
    labelSituationActive: "ACTIVE",
    titleSituationActive: "Show only active models",
    labelSituationInactive: "INACTIVE",
    titleSituationInactive: "Show only inactive models",
    placeholderModelName: "Enter the model name",
    labelNoDataModel: "No model found in the provided filter",
    labelModelName: "Model",
    errorModelName: "* Required",
    titleModelAdd: "Model registration",
    subtitleModelAdd: "Enter below the name of the new model to be inserted",
    titleName: "Name",
    titleBtnCancel: "Cancel model editing",
    titleBtnEdit: "Click to edit the model",
    titleModelEdit: "Model visualization and editing",
    titleModel: "Model maintenance",
    subtitleModel: "Click on one of the models to see the available options",
  },
  manutencao_perda: {
    labelWaitDesenv: "Wait... Page under development",
  },
  manutencao_pilar: {
    labelWaitDesenv: "Wait... Page under development",
  },
  dados_cadastrais: {
    errorServer: "Internal server error",
    male: "Male",
    female: "Female",
    depLabelNameDepend: "Dependent's name",
    depPlaceholerNameDepend: "Dependent's name",
    depLabelCpfDepend: "Dependent's CPF",
    depPlaceholerCpfDepend: "EX: 000.000.000-00",
    depLabelBirthDepend: "Date of birth",
    depPlaceholerBirthDepend: "dd/mm/yyyy",
    depLabelSexDepend: "Sex",
    depLabelSchoolingDepend: "Education",
    depLabelDegreeDepend: "Degree of kinship",
    depErrorNameDepend: "* Minimum of 3 characters",
    depErrorSexDepend: "* Required",
    depErrorCpfDepend: "* EX: 000.000.000-00",
    depErrorBirthDepend: "* EX: 00/00/0000",
    depErrorSchoolingDepend: "* Required",
    depErrorDegreeDepend: "* Dependent's name",
    titleDepend: "Dependent maintenance form",
    depConfirmDelete: "Do you really want to delete this dependent?",
    depTitleBtnCConfigm: "Click to confirm the dependent's registration",
    depLabelBtnConfigm: "Confirm",
    depTitleBtnEdit: "Click to change some field of the dependent",
    depLabelBtnEdit: "Edit",
    depTitleBtnDelete: "Click to remove this dependent",
    depLabelBtnDelete: "Delete",
    depLabelBirthNasDepend: "Birth",
    depLabelCpfDepend2: "CPF",
    titlePage: "Dependents",
    titleFabAddDepend: "Click to add a dependent",
    labelFabAddDepend: "Add Dependent",
    subtitleListOfDependent:
      "List with dependents linked to your registration. Check the information and confirm them (when necessary) or edit the dependent by deleting it.",
    titleNotDependent: "No dependents were found for you",
    labeName: "Name",
    placeholderName: "Full Name",
    labelCpf: "CPF",
    placeholderCpf: "EX: 000.000.000-00",
    labelPhone: "Phone",
    placeholderPhone: "EX: (00) 00000-0000",
    labelRg: "RG",
    placeholderRg: "Write here",
    labelEmail: "E-mail",
    placeholderEmail: "email@example.com",
    labelBirth: "Birth",
    placeholderBirth: "DD/MM/YYYY",
    labelCourse: "Course",
    placeholderCourse: "Course ...",
    labelCourseDate: "Course date",
    labelMaritalStatus: "Marital status",
    labelSchooling: "Education",
    labelDisability: "Disability",
    labelDisabilityType: "Type of disability",
    labelCep: "Postal Code",
    placeholderCep: "EX: 00000-000",
    labelAddress: "Address",
    placeholderAddress: "Example Street",
    labelNumber: "Number",
    placeholderNumber: "Ex: 100",
    labelComplement: "Complement",
    placeholderComplement: "complement",
    labelCityCode: "City code",
    placeholderCityCode: "City code",
    labelDistrict: "District",
    placeholderDistrict: "Example District",
    labelCity: "City",
    placeholderCity: "City",
    labelState: "State",
    placeholderState: "State",
    labelReferencePoint: "Reference point",
    placeholderReferencePoint: "Reference point",
    labelEmergencyName: "Emergency name",
    placeholderEmergencyName: "Emergency name",
    labelEmergencyContact: "Emergency contact",
    placeholderEmergencyContact: "EX: (00) 00000-0000",
    labelAttachments: "Attachment(s)",
    errorName: "* Required",
    errorCpf: "Ex: 000.000.000-11",
    errorPhone: "Ex: (00) 00000-0000",
    errorRg: "* Minimum 3 characters",
    errorEmail: "Ex: example@email.com",
    errorBirth: "Ex: 00/00/0000",
    errorMaritalStatus: "* Choose a marital status",
    errorSchooling: "* Choose an education",
    errorDisability: "* Required",
    errorDisabilityType: "* Required",
    errorCep: "* EX: 00000-000",
    errorAddress: "* Required",
    errorNumber: "* Required",
    errorCityCode: "* Required",
    errorDistrict: "* Required",
    errorCity: "* Required",
    errorState: "* Required",
    errorReferencePoint: "* Required",
    errorEmergencyName: "* Required",
    errorEmergencyContact: "* Ex: (00) 00000-0000",
    errorCepNot200:
      "Error when trying to consult the postal code. Try again later.",
    errorCepNotFound: "Nonexistent postal code",
    titleFormUpd: "Registration Data",
    titleUpdPasswd: "User Settings",
    titleForm: "Password Update",
    titleField1: "New Password",
    titleField2: "Confirm Password",
    placeholderField1: "Enter the new password",
    placeholderField2: "Confirm the new password",
    btnUpdate: "Update Password",
    msgError: "THE PASSWORDS ARE NOT THE SAME",
    msgFieldError: "Minimum of 3 characters",
    cardTitleName: "Name",
    cardTitleCpf: "CPF",
    cardTitlePhone: "Phone",
    cardTitleBirth: "Birth",
    cardTitleRg: "RG",
    cardTitleEmail: "E-mail",
    cardTitleMaritalStatus: "Marital Status",
    cardTitleSchooling: "Education",
    cardTitleCourse: "Course",
    cardTitleCourseDate: "Course Date",
    cardTitleDisability: "Disability",
    cardTitleDisabilityType: "Type of Disability",
    cardTitleCep: "Postal Code",
    cardTitleStreet: "Street",
    cardTitleNumber: "N°",
    cardTitleComplement: "Complement",
    cardTitleReferencePoint: "Reference Point",
    cardTitleNeighborhood: "Neighborhood",
    cardTitleCity: "City",
    cardTitleState: "State",
    cardTitleMunicipalityCode: "Municipality Code",
    cardTitleEmergencyName: "Emergency Name",
    cardTitleEmergencyContact: "Emergency Contact",
    cardTitleAttachment: "Attachment",
    cardTitleAttachmentClickHere: "Click here",
    approved: "Approved",
    inAnalysis: "In Analysis",
    pending: "Pending",
    titleApproved: "Information received and confirmed",
    titleInAnalysis: "Information pending analysis",
    titlePending: "Awaiting situation definition",
    tab1: "Personal",
    tab2: "Dependents",
    tab3: "Password",
  },

  dados_cadastrais_page: {
    titlePage: "Registration data relation",
    subtitlePage: "Click on a record to change its status, or view dependents.",
    titleViewAttachments: "Attachment(s) related to the registration",
    subtitleViewAttachments:
      "Below is a list of all attachments that were sent by the collaborator and are part of this data registration",
    attachment: "Attachment",
    titleBtnAttach1: "There are",
    titleBtnAttach2: "attachment(s) in this record",
    titleConfirm: "Confirm the receipt of the registration",
    titleReject: "Reject the sent registration data",
    titleActiveDeactivePend:
      "Activate/Deactivate the display of pending records",
    titleActiveDeactiveOk:
      "Activate/Deactivate the display of confirmed records",
    titleViewDependents: "View the dependents linked to the collaborator",
    titleDependents: "Dependents related to the collaborator",
    errorInternal: "INTERNAL SERVER ERROR",
    errorUnknown: "Unknown error",
    labelFilterPendent: "PENDING",
    labelFilterOK: "OK",
  },
  banco_de_horas: {
    titlePage: "Bank of hours",
    titleUpdateCache: "Update the bank of hours cache",
    saldoFinal: "FINAL BALANCE",
  },

  documentos: {
    detailsHeaderFileVisibleForPlant: "Visible for everyone from",
    detailsHeaderFileOwner: "File owner",
    editNameFileNewName: "New Name",
    editNameFileNewNamePlaceholder: "Enter the new file name",
    editNameFileNewNameError: "* Minimum of 3 characters",
    editNameFileTitle: "Enter the new name",
    labelEditVisibilityBy: "Visible by",
    labelEditFuncPermissionView:
      "Employees with permission to view (leave blank for no restriction)",
    errorVisibilityBy: "* Choose at least one plant to view",
    titleEditVisibility: "Set visibility level",
    labelItemFileMobileCreated: "Created",
    labelItemFIleMobileFile: "File",
    labelItemCreated: "Created",
    labelItemFile: "File",
    withPermissionToView: "has permission to view",
    labelItemMenuDetails: "Details",
    labelItemMenuRename: "Rename",
    labelItemMenuCut: "Cut",
    labelItemMenuAlterVisibility: "Change Visibility",
    labelItemMenuDelete: "Delete",
    labelItemTrashDetails: "Details",
    labelItemTrashRecover: "Recover from trash",
    labelItemTrashDeleteForever: "Delete permanently",
    titleConfirmDelete: "Do you really want to delete?",
    subtitleConfirmDelete:
      "This action cannot be undone. If it is a directory, all files below will also be removed.",
    subtitleConfirmDeleteFile:
      "This action cannot be undone. The item will be permanently deleted from the trash",
    labelNewFolder: "Folder name",
    placeholderNewFolder: "Enter the folder name",
    labelVisibilityBy: "Visible by",
    labelFuncPermittedView:
      "Employees with permission to view (leave blank for no restriction)",
    errorNewFolder: "* The name must have at least 2 characters.",
    errorNotFiles: "Files must exist",
    titleNewFolder: "Creating a new folder",
    titleTrash: "Trash of deleted items",
    labelNewFiles: "New files",
    placehlderNewFiles: "Choose files to send",
    titleUploadNewFiles: "New files in the folder",
    errorInternalServer: "INTERNAL SERVER ERROR",
    titleViewFile: "Details about the item",
    file: "File",
    fromFile: "from file",
    fromFolder: "from folder",
    created: "Created",
    createdBy: "Created by",
    linkToDownload: "Download Link",
    secondaryLinkToDownload: "Click to download the file",
    titleAccessRestricted: "Access restricted only to",
    titleAccessPermitted: "Access allowed to",
    labelAllPlantsView: "Everyone from the plants with view can see",
    labelBtnGoBack: "BACK",
    labelBtnZoomOut: "ZOOM OUT",
    labelBtnZoomIn: "ZOOM IN",
    labelBtnNext: "NEXT",
    labelBtnClose: "CLOSE",
    descPageOf1: "Page",
    descPageOf2: "of",
    titleAccessTrash: "Access to trash",
    titleUploadFiles: "Upload files",
    titleCreateFolder: "Create new folder",
    labelBtnCancel: "Cancel",
    labelBtnPaste: "Paste Here",
    titleAnimationNoData: "There are no files in this folder",
  },
  oportunidades: {
    titleHistVacation: "Candidate's job history",
    fromPhase: "From phase",
    toPhase: "To phase",
    observation: "Observation",
    btnHistAttachment: "Attachment",
    histData: "Date",
    errorInternal: "INTERNAL SERVER ERROR",
    errorUnknown: "UNKNOWN ERROR",
    illiterate: "ILLITERATE",
    incompletePrimary: "UP TO 4th INCOMPLETE SERIES (INCOMPLETE PRIMARY)",
    completePrimary: "WITH 4th COMPLETE SERIES OF 1st GRADE (COMPLETE PRIMARY)",
    incompleteSecondary: "FIRST GRADE (MIDDLE SCHOOL) INCOMPLETE",
    completeSecondary: "FIRST GRADE (MIDDLE SCHOOL) COMPLETE",
    incompleteHighSchool: "SECOND GRADE (HIGH SCHOOL) INCOMPLETE",
    completeHighSchool: "SECOND GRADE (HIGH SCHOOL) COMPLETE",
    incompleteCollege: "INCOMPLETE COLLEGE",
    completeCollege: "COMPLETE COLLEGE",
    completeMaster: "COMPLETE MASTER",
    completeDoctorate: "COMPLETE DOCTORATE",
    specialization: "POST-GRADUATE/SPECIALIZATION",
    postDoctorate: "POST-DOCTORATE",
    labelWaitGeneratePDF: "Wait, generating PDF...",
    isOk: "Filled",
    isOpen: "Open",
    labelBtnClose: "Close",
    titleScholarship: "Education",
    titlePhone: "Mobile",
    titleDateInscription: "Registration date",
    titleSector: "Sector",
    titleEmail: "E-mail",
    titleAttachment: "Candidate's attachment(s)",
    attachment: "Attachment",
    titleAnswerQuestionnaire: "Questionnaire answers",
    titleBtnDownloadPdf: "Click here to download a PDF version",
    labelBtnDownloadPdf: "DOWNLOAD ANSWERS",
    hidden: "Hide",
    show: "Show Registered Candidates for the job",
    labelPhaseActualy: "Current phase",
    titleVacationPage: "Candidates for the job",
    phase: "PHASE",
    titleBtnViewHistory: "View the history of movements",
    labelBtnViewHistory: "History",
    titleBtnViewAnswers: "View questionnaire answers",
    labelBtnViewAnswers: "Answers",
    titleBtnChangePhase: "Change the candidate to the next phase",
    labelBtnChangePhase: "Change Phase",
    labelEmail: "E-mail",
    placeholderEmail: "Your email",
    labelPhone: "Mobile",
    placeholderPhone: "(00) 00000-0000",
    labelSchooling: "Education *",
    placeholderWriteHere: "Write here",
    errorQuestion: "* Please answer the question",
    labelToPhase: "Next phase to move",
    labelObservation: "Observation",
    placeholderObservation: "Enter the next phase here",
    labelFile: "File",
    placeholderFile: "Enter a file",
    errorObservation: "* Please enter at least 3 characters",
    errorToPhase:
      "* Choose the phase in which the candidate will be moved to the next phase",
    titleAlterPhase: "Phase change",
    subtitleNewPhase:
      "Indicate the new phase in which the candidate has been transferred.",
    labelOpenDate: "Opening date",
    labelVacation: "Job",
    labelFunction: "Function",
    labelDescription: "Description",
    labelActivity: "Activity",
    labelIamCandidate: "I am already a candidate",
    labelRegister: "APPLY",
    titleOpenDate: "Job inclusion date",
    titlePage: "Opportunities",
    titleNoData: "No opportunities at the moment",
    titleSituationActualy: "Current job situation",
  },
  painel_de_notificacao: {
    titlePage: "Notification Panel",
    subtitleSendMessageBySector: "Sending messages by sector",
    titleSendNotificationBySector: "Sending notifications by sector",
    titleSendNotificationByPlant: "Sending notifications by plant",
    errorTitle: "Minimum of 3 characters",
    errorDescription: "Minimum of 3 characters",
    errorSector: "Select at least one sector",
    labelSector: "Choose the sector",
    labelTitle: "Title",
    placeholderTitle: "Write a title",
    labelDescription: "Description",
    placeholderDescription: "Write a description",
    labelBtnRemoveSector: "Remove selected sectors",
    titleCanReceiveNotifications: "Can receive notifications",
    titleCannotReceiveNotifications: "Cannot receive notifications",
    labelClearFilter: "Clear filter",
    titlePageSendOneNotification: "Sending an individual notification",
    subtitlePageSendOneNotification1: "Notifying the user",
    subtitlePageSendOneNotification2: "of the sector",
    titlePageSendPlantNotification: "Sending an individual notification",
    subtitlePageSendPlantNotification:
      "Fill in the fields to send a notification to the entire plant of",
  },

  manutencao_componentes: {
    titlePage: "Maintenance of Structures",
    titleFab: "Insertion of a New Component",
    labelFab: "NEW COMPONENT",
    components: "components",
    descriptionPortuguese: "Portuguese Description",
    titlePageFormAdd: "INSERTION OF A NEW COMPONENT",
    labelCode: "Code",
    placeholderCode: "Code",
    labelDescriptionPortuguese: "Portuguese Description",
    placeholderDescriptionPortuguese: "Description in Portuguese",
    labelDescriptionEnglish: "English Description",
    placeholderDescriptionEnglish: "Description in English",
    labelDescriptionItalian: "Italian Description",
    placeholderDescriptionItalian: "Description in Italian",
    labelComponentMath: "Component Math",
    placeholderComponentMath: "Component Math",
    labelSupplier: "Supplier",
    placeholderSupplier: "Supplier",
    labelType: "Type",
    placeholderType: "Type",
    labelMass: "Mass",
    placeholderMass: "Mass",
    labelMaterialStandard: "Material Standard",
    placeholderMaterialStandard: "Painting Specification",
    labelWeldingAreaSpecification: "Welding Area Specification",
    placeholderWeldingAreaSpecification: "Welding Area Specification",
    labelUnitMeasure: "Unit of Measure",
    placeholderUnitMeasure: "Unit of Measure",
    labelThreadProtectiveApplication: "Thread Protective Application",
    placeholderThreadProtectiveApplication: "Thread Protective Application",
    labelComponentImage: "Component Image",
    placeholderComponentImage: "file",
    expectMax: "* Expected at most",
    characters: "characters",
  },
  informe_rendimentos: {
    titlePage: "Income Statement",
    titleNoData: "Click on any year to download the income statement",
    titleChip: "Year of",
    titleAnimation: "Income statement. Click the button to download",
    titleButton: "Click to download the statement for the year of",
    labelButton: "DOWNLOAD YEAR",
  },

  budget: {
    titleClickValue: "Click to see details",
    infoTable: "* Click on the REAL VALUE amounts to see the details",
    month: "",
    nothing: "There are no data to be returned",
    tab1: "Summary",
    tab2: "Detailed",
    title: "Details",
    headerDescription: "ACCOUNT DESCRIPTION",
    headerHistory: "HISTORY",
    headerClientSupplier: "CLIENT / SUPPLIER",
    headerMonth: "MONTH",
    headerAccount: "ACCOUNT",
    headerValue: "VALUE",
    titleNoData: "No data",
  },
  ncp: {
    tituloBuscaAvancada: "Advanced Search",
    descricaoBuscaAvancada:
      "Choose from the options and build an advanced search.",
    tituloNaoConformidade: "NON-CONFORMITY EVIDENCE",
    rotuloBtnFiltro: "ADVANCED SEARCH",
    tituloSemDados: "No data found",
    tituloSemDadosRetorno: "Trying to load necessary data...",
    titulo: "NCP",
    tituloPaginacao: "Click to toggle between pages",
    codigo: "Code",
    anomalia: "Anomaly: ",
    data: "Creation Date: ",
    area: "Area: ",
    rotuloBotaoAdd: "New NCP",
    tituloBotaoAdd: "Create new NCP",
    tituloForm: "PROCESS NON-CONFORMITY ALERT",
    formAuditor: "AUDITOR",
    formConjunto: "SET",
    formModelo: "MODEL",
    formTurno: "SHIFT",
    formQtdKO: "KO QUANTITY",
    formQtdSuspeita: "SUSPECT QUANTITY",
    formOperacao: "OPERATION",
    formNCP: "NCP N°",
    formPN: "PN",
    formRastreabilidade: "TRACEABILITY",
    formData: "DATE",
    formHora: "HOUR",
    formErrorAuditor: "Please inform at least one employee",
    formErrorConjunto: "Choose at least one area",
    formErrorModelo: "Choose at least one model",
    formErrorTurno: "Choose at least one shift",
    formErrorQtdKO: "Minimum 0",
    formErrorQtdSuspeita: "Minimum 0",
    formErrorOperacao: "Operation",
    formErrorNCP: "NCP N°",
    formErrorPN: "Inform the PN",
    formErrorRastreabilidade: "Inform traceability",
    formErrorSubidaDeLinha: "Did it move up the line?",
    formErrorFalhaForaControle: "Was it an out-of-control failure?",
    formErrorTwttp: "Required",
    formErrorData: "Inform the date",
    formErrorHora: "Inform the hour",
    formErrorAnomalia: "Inform the anomaly",
    formAuditorPlaceholder: "Inform the auditor",
    formConjuntoPlaceholder: "Area",
    formModeloPlaceholder: "Model",
    formTurnoPlaceholder: "Shift",
    formQtdKOPlaceholder: "EX: 1",
    formQtdSuspeitaPlaceholder: "EX: 1",
    formOperacaoPlaceholder: "Operation",
    formNCPPlaceholder: "NCP N°",
    formPNPlaceholder: "PN",
    formRastreabilidadePlaceholder: "Traceability",
    formDataPlaceholder: "Date",
    formHoraPlaceholder: "Hour",
    formAnomaliaPlaceholder: "Describe the anomaly...",
    formAnomalia: "ANOMALY",
    tituloPergs: "5W+1H",
    tituloKO: "KO",
    tituloOK: "OK",
    tituloImagemIdent: "RACK OR BOX IDENTIFICATION",
    formOque: "WHAT?",
    formQuando: "WHEN?",
    formOnde: "WHERE?",
    formQuem: "WHO?",
    formQual: "WHICH?",
    formComo: "HOW?",
    formErrorOque: "Required",
    formErrorQuando: "Required",
    formErrorOnde: "Required",
    formErrorQuem: "Required",
    formErrorQual: "Required",
    formErrorComo: "Required",
    formOquePlaceholder: "What happened?",
    formQuandoPlaceholder: "When did it happen?",
    formOndePlaceholder: "Where did it happen?",
    formQuemPlaceholder: "Who identified it?",
    formQualPlaceholder: "What is the problem level?",
    formComoPlaceholder: "How did it occur?",
    formFenomenoRevisado: "REVIEWED PHENOMENON",
    formErrorFenomenoRevisado: "Required",
    formFenomenoRevisadoPlaceholder: "Describe the phenomenon.",
    formCausaRaiz: "POSSIBLE ROOT CAUSE",
    formCausaRaizPlaceholder: "Describe here...",
    formErrorCausaRaiz: "Required",
    tituloBotaoVoltar: "Click to return",
    rotuloBotaoFormSalvar: "SAVE",
    tituloBotaoRemoverImagem: "Click to remove Image",
    formRotuloImagem: "Insert the image here",
    formErrorImagemOK: "Image required",
    formErrorImagemKO: "Image required",
    formErrorImagemIdent: "Image upload error",
    situacaoQualidade: "QUALITY",
    situacaoSubida: "LINE MOVE UP",
    situacaoAberto: "OPEN",
    opcaoDetalhes: "Details",
    opcaoSubida: "Line Move Up",
    opcaoDownloadPdf: "Download PDF",
    opcaoQualidade: "Move to Quality",
    rotuloBotaoVerOpcoes: "OPTIONS",
    tituloBotaoVerOpcoes: "Displays the NCP manipulation options",
    tituloFormSubida: "Line Move Up Form",
    formPlaceholderTempoGasto: "Inform the time spent",
    formTempoGasto: "Time spent",
    formPlaceholderMod: "Inform direct labor",
    formMod: "DL",
    formPlaceholderMoi: "Inform indirect labor",
    formMoi: "IL",
    formPlaceholderOK: "Inform the OK quantity",
    formOK: "OK Quantity",
    formPlaceholderKO: "Inform the KO quantity",
    formKO: "KO Quantity",
    formPlaceholderObservacao: "Describe an observation",
    formObservacao: "Observation",
    formErrorTempoGasto: "* Please inform the time spent HH:MM",
    formErrorMod: "* Minimum value 0",
    formErrorMoi: "* Minimum value 0",
    formErrorOK: "* Minimum value 0",
    formErrorKO: "* Minimum value 0",
    formErrorObservacao: "* Describe an observation minimum 3 characters",
    tituloFormQualidade: "Quality Form",
    formPlaceholderCodigoProjeto: "Inform the project code",
    formCodigoProjeto: "Project Code",
    formPlaceholderProjeto: "Does this NCP have any project link?",
    formProjeto: "Does this NCP have any project link?",
    formPlaceholderAcoes: "Describe the actions",
    formAcoes: "Action",
    formErrorProjeto: "* Choose Yes or No",
    formErrorCodigoProjeto: "* Inform the project code",
    formErrorAcoes: "* Describe the action minimum 3 characters",
    abaForm1: "NCP",
    abaSubida: "LINE MOVE UP",
    abaQualidade: "QUALITY",
    rotuloBotaoVoltar: "BACK",
    subidaLinhaMod: "Direct Labor",
    subidaLinhaMoi: "Indirect Labor",
    subidaLinhaTempo: "Work Time",
    subidaLinhaOK: "OK Pieces Quantity",
    subidaLinhaKO: "KO Pieces Quantity",
    subidaLinhaObs: "Labor Observation",
    qualidadeCodProjeto: "Project Number",
    qualidadeObs: "Actions",
    filtroNomeStatus: "STATUS",
    filtroNomeStatusErro: "* Choose at least one",
    filtroNomeMatricula: "REGISTRATION",
    filtroNomeMatriculaErro: "* Choose at least one",
    filtroNomeArea: "AREA",
    filtroNomeAreaErro: "* Choose at least one",
    filtroNomeTurno: "SHIFT",
    filtroNomeTurnoErro: "* Choose at least one",
    filtroNomeNcp: "NCP CODE",
    filtroNomeNcpErro: "* Choose at least one",
    filtroNomeOperacao: "OPERATION",
    filtroNomeOperacaoErro: "* Choose at least one",
    filtroNomePN: "PN",
    filtroNomePNErro: "* Choose at least one",
    opcaoExcluirNcp: "Delete NCP",
    opcaoRetornarAbertoNcp: "Return to open",
    opcaoRetornarSubidaDeLinhaNcp: "Return to line move up",
    titlePageCancel: "Do you really want to cancel this status?",
    subtitleCancel: "The NCP will be deleted and cannot be recovered",
    subtitleCancel2: "The NCP will return to the OPEN status",
    subtitleCancel3: "The NCP will return to the LINE MOVE UP status",
  },
  treinamento: {
    labelResponsibility: "Responsibility",
    trainningDateTraining: "Training Date",
    instructor: "Instructor",
    local: "Location",
    titleViewTrainning: "View Training Information",
    labelViewTrainning: "VIEW TRAINING",
    labelBtnOptions: "Options",
    titleEditTrainning: "Edit Training Information",
    labelEditTrainning: "EDIT TRAINING",
    titleAddParticipant: "Add students to training",
    labelAddParticipant: "ADD STUDENTS",
    labelViewHistory: "VIEW HISTORY",
    titleViewHistory: "VIEW TRAINING HISTORY",
    labelAlterStatus: "CHANGE STATUS",
    titleAlterStatus: "Change training status",
    optionTextNewTrainning: "New Training",
    optionTitleNewTrainning: "Click here to register new trainings",
    optionTextMaintenceCouse: "Course Maintenance",
    optionTitleMaintenceCouse: "Click here for course maintenance",
    optionTextProfessor: "Instructor Maintenance",
    optionTitleProfessor: "Click here for instructor maintenance",
    titlePage: "Training",

    titlePaginationFilter: "Paginated display.",
    trainningNoData: "No data to display",
    labelDescription: "Description",
    placeholderDescription: "Course description",
    labelDuration: "Duration",
    placeholderDuration: "Course duration",
    labelModel: "Model",
    placeholderModel: "Course model",
    errorDescription: "Minimum of 1 and maximum of 150 characters",
    errorDuration: "Invalid format. Example: 00:00",
    trainningAddUpdInstTitleAdd: "Add Instructor",
    trainningAddUpdInstTitleUpd: "Update Instructor",
    trainningAddUpdInsLabelName: "Name",
    trainningAddUpdInsErrorName:
      "The instructor's name must be between 1 and 250 characters",
    trainningAddUpdInsErrorMat:
      "The instructor's registration must have 6 characters",
    trainningAddUpdInsErrorPlant: "Select the plant",
    trainningAddUpdInsErrorOrigin: "Select the instructor's origin",
    trainningAddUpdInsErrorEntity:
      "The instructor's entity must be between 1 and 150 characters",
    trainningAddUpdInsPlaceholderName: "Instructor's name",
    trainningAddUpdInsPlaceholderMat: "Instructor's registration",
    trainningAddUpdInsPlaceholderPlant: "Select the plant",
    trainningAddUpdInsPlaceholderEntity: "Instructor's entity",
    trainningAddUpdInsLabelMat: "Registration",
    trainningAddUpdInsLabelPlant: "Plant",
    trainningAddUpdInsLabelOrigin: "Instructor's origin",
    trainningAddUpdInsLabelEntity: "Entity",
    trainningAddUpdInsLabelInstructor: "Internal instructor",
    titleTrainningCourse: "Course Maintenance",
    textFabNewCouse: "New Course",
    titleFabNewCouse: "Click here to register new courses",
    labelCourseBtnOptions: "Options",
    titleEdtCourse: "Edit Course",
    labelEdtCourse: "Edit",
    titleDelCourse: "Delete Course",
    labelDelCourse: "Delete",
    titleDeleteTrainning: "Delete Training",
    labelDeleteTrainning: "DELETE TRAINING",
    titlePageDeleteTrainning: "Delete Training",
    subtitlePageDeleteTrainning: "Do you really want to delete this training?",
    subtitleDelCourse: "Do you really want to delete the course?",
    titleDelInstructor: "Delete Instructor",
    subtitleDelInstructor: "Do you really want to delete the instructor?",
    titleFormAddNewCourse: "Add new training",
    labelCourse: "* Course",
    labelInstructor: "* Instructor",
    labelDateInit: "* Start date",
    labelLocal: "* Location",
    placeholderCourse: "Select a course",
    placeholderInstructor: "Select an instructor",
    placeholderDateInit: "Enter the start date",
    placeholderLocal: "Enter the location",
    errorCourse: "Select a course.",
    errorInstructor: "Select an instructor.",
    errorDateInit: "Enter the start date.",
    errorLocal: "Enter the location.",
    labelTypeOfCourse: "Type of course",
    labelEfficacy: "Efficacy",

    labelTheme: "Themes",
    labelApply: "Development (Where applicable)",
    labelDevelopment: "Training Development",
    labelObservation: "Observations",
    labelDocument: "Documents",
    labelMoreTimeEfficiency: "Need more time to verify efficacy?",
    labelMoreTrainning: "Need more training?",
    labelApproved: "For course approval",
    labelFinish: "For relationship closure",
    placeholderTypeOfCourse: "Enter the type of course",
    placeholderEfficacy: "Enter the efficacy",

    placeholderTheme: "Enter the themes",
    placeholderApply: "Enter the development where applicable",
    placeholderDevelopment: "Enter the training development",
    placeholderObservation: "Enter the observations",
    placeholderDocument: "Enter the documents",
    placeholderMoreTimeEfficiency:
      "Enter if more time is needed to verify efficacy",
    placeholderMoreTrainning: "Enter if more training is needed",
    placeholderApproved: "Enter for course approval",
    placeholderFinish: "Enter for relationship closure",
    labelReponsibility: "* Responsible",
    placeholderResponsibility: "Enter the responsible person",
    labelDateRealized: "Realization date",
    placeholderDateRealized: "Enter the realization date",
    labelPresence: "Presence",
    placeholderPresence: "Enter the presence",
    labelCertificate: "Certificate",
    placeholderCertificate: "Enter the certificate",
    errorResponsibility: "Enter the responsible person.",
    trainningFormAlterStatusTitle: "Change training status",
    trainningFormAlterStatusObservation: "Observation",
    trainningFormAlterStatusErrorObservation: "Observation is required",
    trainningFormAlterStatusFrom: "From",
    trainningFormAlterStatusTo: "To",
    trainningInstructorTitle: "Instructor Maintenance",
    textFabNewInstructor: "New Instructor",
    titleFabNewInstructor: "Add new instructor",
    tilteEdtInstructor: "Edit Instructor",
    labelEdtInstructor: "Edit",

    labelDelInstructor: "Delete",
    titleFormStudents: "Add students",
    titleFormStudentsSubtitle: "Add students to training",
    studentNumber: "Student No.",
    studentNewAdd: "Add student to training",
    studentRemove: "Remove student from training",
    labelBtnSaveStudents: "SAVE",
    labelBtnClearAllStudents: "REMOVE ALL",
    statusOpen: "OPEN",
    statusInTraining: "IN TRAINING",
    statusDelivered: "MINUTES DELIVERED",
    trainningViewHistoryTitle: "View training history",
    trainningViewHistoryLabelMat: "Registration",
    trainningViewHistoryLabelName: "Name",
    trainningViewHistoryLabelFromStatus: "From status",
    trainningViewHistoryLabelToStatus: "To status",
    trainningViewHistoryLabelDate: "Record date",
    trainningViewHistoryLabelObservation: "Observation",
    titleBtnGoBack: "Click to go back",
    labelBtnGoBack: "GO BACK",
    labelNoDataTrainning: "No training found",
    titleListOfTrainning: "TRAINING LIST",
    labelCodeN: "RAD No.",
    labelData: "DATE",
    titleTypeOfTrainning: "TYPE OF TRAINING",
    titleEfficiency: "EFFICACY, to be evaluated by:",
    labelEfficiencyInfo:
      "Please, at the end of the RAD, describe the efficacy evaluation methodology and attach evidence where applicable",
    labelLocale: "LOCATION",

    themeAndAttachs: "THEMES AND ATTACHMENTS",
    trainningName: "(Name)",
    trainningAssigned: "(Signature)",
    trainningProffessor: "INSTRUCTOR(S)",
    trainningPersonal: "TRAINER(S)",
    titleDevelop: "Where applicable: EXPECTED TRAINING DEVELOPMENT",
    tableCellNumber: "BADGE NUMBER",
    tableName: "FIRST AND LAST NAME",
    tableAssigned: "SIGNATURE",
    tableAvailable: "EFFICACY EVALUATION\n(result, date)",
    titleDevelopFormattion:
      "ACHIEVED TRAINING DEVELOPMENT (after efficacy verification)",
    subtitleDevelopFormattion1: "Level",
    subtitleDevelopFormattion2:
      "reached the level? Enter the number (#) associated with the employee.",
    labelObservationInfo: "OBSERVATION",
    labelDocumentDistribution: "DISTRIBUTED DOCUMENTS",
    titleMoreEfficiency: "Need more time to verify efficacy?",
    titleMoreTrainning:
      "In case of negative or partially positive efficacy evaluation result for one or more participants:",
    titleMoreTrainning2: "Need more training?",
    toApproved: "FOR COURSE APPROVAL (HR/EHS/QS/PLM)",
    toFinish: "FOR RELATIONSHIP CLOSURE (HR/EHS/QS/PLM)",
    downloadPDF: "Download PDF",
    clearFilter: "Clear filter",
    optionTextDownloadExcel: "Download Excel",
    optionTitleDownloadExcel: "Click here to download the data in Excel",
    trainningDownloadExcelTitle: "Download Excel",
    trainningDownloadExcelLabelDateFrom: "Training date from",
    trainningDownloadExcelLabelDateTo: "Training date to",
    trainningDownloadExcelErrorDateFrom: "Training date from is required",
    trainningDownloadExcelErrorDateTo: "Training date to is required",
  },
};

export default translate;
